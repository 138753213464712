import {
  coreApiClient,
  legacyApiClient,
  pegasusApiClient,
} from './APIService';
import {
  endpoints,
  versionHeader,
  coreEndpoints,
} from './constants';
import { responseHandler } from './responseInterceptors/responseHandler';
import getAuthHeaders from './getAuthHeaders';
import { requestInterceptor } from './requestInterceptors/requestInterceptor';
import { coreBackendResponseHandler } from './responseInterceptors/coreBackendEndpointResponseInterceptor';

const pegasusClient = pegasusApiClient();
const coreClient = coreApiClient();
const legacyClient = legacyApiClient();

requestInterceptor(pegasusClient);
requestInterceptor(coreClient);
responseHandler(pegasusClient);
coreBackendResponseHandler(coreClient);

export {
  pegasusClient,
  coreClient,
  legacyClient,
  endpoints,
  coreEndpoints,
  versionHeader,
  getAuthHeaders,
};
