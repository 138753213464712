import {
  Link,
  Typography,
} from '@wheelsup/wu-react-components';
import { ICardPrivateFlightSearchResult_v2 } from './CardPrivateFlightSearchResult.interfaces';
import {
  AircraftInformation,
  CardContainer,
  FlightInfoLineItem,
  FlightInfoList,
  IconWithMargin,
  ImageAndAircraftInfo,
  MemberInformation,
  PriceAndTaxInfo,
} from './CardRestyledDesktop.styles';
import { useViewport } from '@hooks';
import { ImageCarousel } from '@atoms';
import { trackAnalytics } from '@services/mixpanelService/mixpanel.service';
import { useFeatureFlag } from '@services/featureFlagService/featureFlagService.services';
import { FeatureFlags } from '@services/featureFlagService/constants';
import { getFromSessionStorage } from '@components/utils/storage';
import { useEffect, useState } from 'react';
import { BREAKPOINTS } from '@components/utils/breakpoints';

export const reservationTypeMap = {
  'instant-book': 'Book',
  'request-quote': 'Request Quote',
};

const CardRestyledDesktop = ({
  aircraftImages,
  aircraftTypeLine1,
  aircraftTypeLine2,
  paxAvailabilityText,
  flightDurationText,
  price,
  fuelStopText,
  selectFlight,
  flightTagColor,
  flightTagText,
  surfaceAction,
  reservationType,
  taxesAndFeesText,
  memberSavingsText,
  flightTripType,
  smallPriceText,
}: ICardPrivateFlightSearchResult_v2) => {
  const flightTypeMap = {
    'one-way': 'ONE WAY',
    'round-trip': 'ROUND TRIP',
  } as any;

  const { width } = useViewport();
  const useTod = useFeatureFlag(FeatureFlags.USE_TOD);
  const [tripType, setTripType] = useState();

  useEffect(() => {
    const fTripType = getFromSessionStorage(
      'searchCriteria',
    ).flightType;
    setTripType(fTripType);
  }, []);

  const handleClickFlightCard = () => {
    const analytics = surfaceAction?.actionAnalytics;
    if (analytics && analytics.properties) {
      trackAnalytics({
        trackingTitle: analytics?.trackingTitle,
        properties: [
          ...(analytics?.properties ?? []),
          {
            key: 'aircraftType',
            value: aircraftTypeLine2,
          },
          ...(flightTagText
            ? [{ key: 'flightTag', value: flightTagText }]
            : []),
        ],
      });
    }
    selectFlight();
  };

  return (
    <CardContainer>
      <ImageAndAircraftInfo>
        <ImageCarousel
          imageURLs={
            aircraftImages?.map((i) => i.url) || []
          }
          heightRem={18}
          widthRem={24.2}
          arrowsInside={true}
          arrowsSize="small"
          borderRadius="10px 0px 0px 10px"
          // feels like theres a better way to do this but i'm not sure how
          tag={
            flightTagText && flightTagColor
              ? { flightTagColor, flightTagText }
              : undefined
          }
        />

        <AircraftInformation
          onClick={handleClickFlightCard}
        >
          <div>
            <Typography
              variant={
                width > BREAKPOINTS.MOBILE
                  ? 'heading02'
                  : 'heading03'
              }
            >
              {aircraftTypeLine2}
            </Typography>
            <Typography
              color="secondary"
              variant={
                width > BREAKPOINTS.MOBILE
                  ? 'body01'
                  : 'body02'
              }
            >
              {aircraftTypeLine1}
            </Typography>
          </div>

          <FlightInfoList>
            <FlightInfoLineItem>
              <IconWithMargin
                name="chair"
                size="s"
                color="tertiary"
              />
              <Typography
                color="secondary"
                variant={'label'}
              >
                {`Up to ${paxAvailabilityText} passengers`}
              </Typography>
            </FlightInfoLineItem>
            {fuelStopText && (
              <FlightInfoLineItem>
                <IconWithMargin
                  name="location"
                  size="s"
                  color="tertiary"
                />
                <Typography
                  color="secondary"
                  variant={'label'}
                >
                  {fuelStopText}
                </Typography>
              </FlightInfoLineItem>
            )}
            {flightDurationText && (
              <FlightInfoLineItem>
                <IconWithMargin
                  name="clock"
                  size="s"
                  color="tertiary"
                />
                <Typography
                  color="secondary"
                  variant={'label'}
                >
                  {flightDurationText}
                </Typography>
              </FlightInfoLineItem>
            )}
          </FlightInfoList>
        </AircraftInformation>
      </ImageAndAircraftInfo>
      <MemberInformation onClick={handleClickFlightCard}>
        <PriceAndTaxInfo>
          {useTod &&
            reservationType === 'instant-book' &&
            tripType &&
            tripType !== 'Multi city' && (
              <Typography align="right" variant="body02">
                From
              </Typography>
            )}
          <Typography
            align="right"
            variant={
              smallPriceText ? 'numberMed' : 'numberLarge'
            }
          >
            {price}
          </Typography>
          <Typography color="secondary" variant="label">
            {memberSavingsText ? memberSavingsText : ''}{' '}
          </Typography>
          <Typography color="secondary" variant="label">
            {taxesAndFeesText}
          </Typography>
          {(!useTod || tripType == 'Multi city') && (
            <Typography color="secondary" variant="label">
              {flightTypeMap[flightTripType]}
            </Typography>
          )}
        </PriceAndTaxInfo>

        <Link
          onPress={handleClickFlightCard}
          trailingIcon="chevron-right"
        >
          {/** one off for when the tag is membership required */}
          {flightTagText === 'MEMBERSHIP REQUIRED'
            ? 'Request Information'
            : reservationTypeMap[reservationType]}
        </Link>
      </MemberInformation>
    </CardContainer>
  );
};

export default CardRestyledDesktop;
