import { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';

import { ToggleNavigation } from '@components/organisms';
import { executePredefinedSearch } from '@components/utils/flightSearchTools';
import { updateLeg } from '@features/Fly/searchFlightSlice';
import {
  endpoints,
  pegasusClient,
} from '@services/apiService';
import { FlightSearchContext } from '../SubPageFlightSearchV2/FlightSearchContext/FlightSearchContext';
import { useFlightSearch } from '../SubPageFlightSearchV2/FlightSearchContext/FlightSearchHooks';
import {
  HeaderContainer,
  FlightSearchSubPageContainer,
  LegContainerContainer,
  StyledSubmitFlightSearch,
  StyledTypography,
} from '../SubPageFlightSearchV2/SubPageFlightSearchV2.styles';
import { flightTypes } from '../SubPageFlightSearchV2/FlightSearchContext/FlightSearchContext.types';
import { validateFlights } from '../SubPageFlightSearchV2/validateFlights';
import { Typography } from '@wheelsup/wu-react-components';
import { flySearchResultsInstance } from '@app/rootReducer';
import LegContainerV3 from '../SubPageFlightSearchV2/LegContainers/LegContainerV3';
import { useFeatureFlag } from '@services/featureFlagService/featureFlagService.services';
import { FeatureFlags } from '@services/featureFlagService/constants';

const navData = [
  {
    id: 1,
    title: flightTypes.oneWay,
  },
  {
    id: 2,
    title: flightTypes.roundTrip,
  },
  {
    id: 3,

    title: flightTypes.multiCity,
  },
];

const SubPageFlightSearchV3 = () => {
  const [searchParams] = useSearchParams();
  const id = searchParams.get('id');
  const tripType = searchParams.get('tripType');
  const departAirport = searchParams.get('fromAirport');
  const arriveAirport = searchParams.get('toAirport');
  const [hasRetrievedDeal, setHasRetrievedDeal] = useState(
    false,
  );
  const {
    flightType,
    legs,
    contextDispatch,
  } = useFlightSearch(useContext(FlightSearchContext));
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const useTod = useFeatureFlag(FeatureFlags.USE_TOD);

  useEffect(() => {
    if (tripType && (departAirport || arriveAirport)) {
      executePredefinedSearch(
        searchParams,
        contextDispatch,
        setHasRetrievedDeal,
        useTod,
      );
    }
  }, []);

  useEffect(() => {
    if (hasRetrievedDeal) {
      handleSearchSubmit();
    }
  }, [hasRetrievedDeal]);

  const isSearchDisabled = () => {
    let status = true;

    status = legs.some((leg) => {
      return (
        !leg.departureAirport ||
        !leg.arrivalAirport ||
        !leg.date ||
        leg?.departureAirport?.code ===
          leg?.arrivalAirport?.code
      );
    });

    return status;
  };
  const whichLegContainer = () => {
    switch (flightType) {
      case flightTypes.roundTrip:
        return (
          <LegContainerV3
            legNumber={0}
            buttonElement={
              <StyledSubmitFlightSearch
                disabled={isSearchDisabled()}
                styleType={
                  isSearchDisabled()
                    ? 'disabled'
                    : 'primary'
                }
                title={'Search'}
                action={{
                  actionMethod: handleSearchSubmit,
                }}
                height="64px"
              />
            }
          />
        );
      case flightTypes.oneWay:
        return (
          <LegContainerV3
            legNumber={0}
            buttonElement={
              <StyledSubmitFlightSearch
                disabled={isSearchDisabled()}
                styleType={
                  isSearchDisabled()
                    ? 'disabled'
                    : 'primary'
                }
                title={'Search'}
                action={{
                  actionMethod: handleSearchSubmit,
                }}
                height="64px"
              />
            }
          />
        );
      case flightTypes.multiCity:
        return legs.map((leg, index) => (
          <LegContainerV3
            key={`${index}`}
            legNumber={index}
            buttonElement={
              <StyledSubmitFlightSearch
                disabled={isSearchDisabled()}
                styleType={
                  isSearchDisabled()
                    ? 'disabled'
                    : 'primary'
                }
                title={'Search'}
                action={{
                  actionMethod: handleSearchSubmit,
                }}
              />
            }
          />
        ));
      default:
        return <LegContainerV3 legNumber={0} />;
    }
  };

  const fetchDeal = async () => {
    if (!id) return;
    // fetch deal
    try {
      const response = await pegasusClient.get(
        `${endpoints.flyPageDeals}${id}`,
        {
          params: { version: 1, platform: 'web' },
        },
      );
      contextDispatch({
        type: 'replaceLegsForDeals',
        payload: response.data.data.legs,
      });
      setHasRetrievedDeal(true);
    } catch (err) {
      // handle error
      console.log(err, 'error fetching deal');
    }
  };

  useEffect(() => {
    fetchDeal();
  }, []);

  const putContextDataInRedux = () => {
    dispatch(
      updateLeg(
        legs.map((leg) => ({
          ...leg,
          // needed in redux
          flightType: flightType,
        })),
      ),
    );
  };

  const handleSearchSubmit = async () => {
    dispatch(flySearchResultsInstance.actions.cleanState());
    putContextDataInRedux();
    await validateFlights(
      legs,
      flightType,
      dispatch,
      contextDispatch,
      navigate,
      'SINGLE_SEARCH',
      useTod,
    );
  };

  return (
    <FlightSearchSubPageContainer id="FlightSearchContainer">
      <HeaderContainer>
        <StyledTypography variant="heading01">
          Search flights
        </StyledTypography>
        <ToggleNavigation
          data-name="ToggleNavigation"
          items={navData}
          selected={flightType}
          setSelected={(e) => {
            contextDispatch({
              type: 'switchFlightType',
              payload: e,
            });
          }}
        />
      </HeaderContainer>
      <LegContainerContainer>
        {whichLegContainer()}
      </LegContainerContainer>
    </FlightSearchSubPageContainer>
  );
};

export default SubPageFlightSearchV3;
