export const endpoints = {
  accountSearch: 'api/pages/fly/account-search',
  account: '/api/pages/account',
  manageCreditCards: '/api/v1/pages/account/creditcard',
  accountContactUs: '/api/v1/pages/account/contact-us',
  passengerSummary: '/api/v1/pages/account/passenger',
  deletePassenger: (passengerId: string) =>
    `/api/v1/user/passenger?version=1&platform=web&id=${passengerId}`,
  petSummary: '/api/v1/pages/account/pet',
  airportSearch:
    'api/v1/airports/search?version=1&platform=web',
  airportSubpageSaveDeleteAction:
    'api/v1/user/airports?version=1&platform=web',
  airportSubpageSearch:
    'api/v1/pages/airport-subpage-search?version=1&platform=web',
  flightValidate: '/api/v1/flights/validate',
  nearbyAirport:
    '/api/v1/pages/fly/alternate-airport-picker',
  timePicker:
    'api/v1/pages/fly/time-picker?version=1&platform=web',
  datePicker: 'api/v1/pages/fly/date-picker',
  auth: '/auth/oauth/token',
  authAccount: '/auth/account',
  documents: '/api/v1/documents?platform=web&version=1',
  dashboardPage: 'api/v1/pages/dash',
  hubCallUsPage: '/api/v1/pages/hub/call-us',
  hubWebToCasePage: 'api/v1/pages/hub/web-to-case',
  hubFAQPage: '/api/v1/pages/hub/faq',
  hubPage: '/api/v1/pages/hub',
  termsOfService: '/api/user',
  userActions: '/api/v1/user',
  petEdit: '/api/v1/pages/account/pet/edit',
  petCreate: '/api/v1/user/passenger',
  progressiveProfilingSubmit:
    'api/v1/user/progressive-profiling?platform=web&version=1',
  progressiveProfilingSearch:
    '/api/v1/airports/fuzzy-search?version=1&platform=web',
  membersPage: '/api/v1/pages/members',
  membersSubPage: (param = '1') => {
    return `/api/v1/pages/members/${param}?version=1&platform=web`;
  },
  flyPage: '/api/v1/pages/fly',
  flyPageDeals: '/api/v1/trips/pre-defined-search/',
  flySearchResultsPage:
    '/api/v2/pages/flight-search-result',
  flySearchTripDetailsPage:
    '/api/v2/pages/search-trip-details',
  newsPage: '/api/v1/pages/news-updates',
  newsDetailsPage: '/api/v1/pages/news-updates',
  demoPage: '/api/v1/pages/demo',
  contactMe:
    '/api/v1/user?version=1&platform=web&action=inquiry',
  confirmationPage:
    '/api/v3/pages/confirmation-page/instant-book',
  confirmationMarketPage:
    'api/v2/pages/confirmation-page/request-book',

  quoteRequestedConfirmationPage:
    '/api/v1/pages/confirmation-page/request-quote',
  passengerPicker: '/api/v1/pages/fly/passenger-picker',
  myTrips: '/api/v1/pages/my-trips',
  myTripsDetails: '/api/v3/pages/my-trips',
  passengerCreateEdit:
    '/api/v1/pages/account/passenger/edit',
  passengerCreateEditPost: '/api/v1/user/passenger',
  //selectPassengers: 'api/v1/pages/account/passenger',
  selectPassengers:
    'api/v1/pages/sub-page-passenger-selection',
  savedAirports: 'api/v1/user/airports',
  hubWebToCaseSubmit: 'api/v1/user/web-to-case',
  bookOpportunity: 'api/v1/trips/book-opportunity',
  confirmContactDetails:
    'api/v1/pages/confirm-contact-details',
  savePreferredContact: 'api/v1/user/save-contact',
  postBookingCatering: 'api/v1/trips/catering',
  postBookingGroundTransport:
    'api/v1/trips/groundtransport',
  postBookingSpecialRequest: 'api/v1/trips/special-request',
  paymentPage: '/api/v2/pages/payment',
  selectPaymentRequest:
    'api/v1/pages/payment/select-payment',
  addCreditCardRequest:
    'api/v1/pages/payment/add-credit-card',
  saveCreditCardRequest: 'api/v1/payment/add-credit-card',
  checkWireRequest:
    'api/v1/pages/payment/check-wire-payment',
  updatePriceBreakdown:
    'api/v1/payment/update-price-breakdown',
  confirmPurchase: 'api/v1/payment/confirm-purchase',
  upcomingReservationTravelDetails:
    '/api/v1/pages/my-trips/travel-info',
  updatePassengerList: 'api/v1/trips/passengers',
  updateLuggageList: '/api/v2/trips/luggage',
  cancelTripLegFee: '/api/v1/pages/my-trips/cancel-fee', // POST
  cancelTripLegForm: '/api/v1/pages/my-trips/cancel-form', // GET
  cancelTrip: '/api/v1/trips/cancel-flights', // POST
  renew: '/api/v1/pages/account/renew',
  amexEligibilityCheck:
    '/public/amex/public/member/checkEligibility',
  flightDealLock: '/api/v1/marketplace/lock',
  flightDeals: 'api/v2/pages/flight-offer',
  flightDetail: 'api/v2/pages/search-trip-details',
  manageLuggagePage:
    '/api/v1/pages/sub-page-luggage-selection', // GET
  selectLuggage: '/api/v1/pages/sub-page-luggage-selection', // POST
  airmedLanding: 'api/v1/pages/airmed',
  airmedSignUp: 'api/v1/pages/airmed/signup',
  airmedManageIndiviuals:
    'api/v1/pages/airmed/manage-individuals',
  airmedAddIndividualsList:
    'api/v1/pages/airmed/add-individuals',
  airmedAddIndividual: 'api/v1/airmed/add-individual',
  airmedPayment: 'api/v1/pages/airmed/payment',
  selectAirmedPaymentRequest:
    'api/v1/pages/airmed/select-payment',
  confirmAirmedPurchase: 'api/v1/pages/airmed/confirmation',
  limitedTimeDealsPage: '/api/v1/pages/limited-time-deals',
  cancelOffer: '/api/v1/trips/cancel-offer',
  exportStatements: '/api/v1/download/pdf/statements',
  exportActivities: '/api/v1/download/excel/activity',
};

export const coreEndpoints = {
  deltaJoin: '/member-join/public/register-joiner/fly-member/delta',
  generalProductAuthentication: '/auth/link-access-code/authorize'
};

export const legacyEndpoints = {
  currentUserContactDetails: 'ms/api/user/info/me?contactInfo=yes',
  editPassenger: (passengerId: string) =>
    `ms/api/individual/edit/${passengerId}`,
};

export const versionHeader = `Wheelsup/1.0.0 ${navigator.userAgent}`;

export const cachePageEndpoints = new Set([
  endpoints.dashboardPage,
  endpoints.flyPage,
  endpoints.myTrips,
  endpoints.account,
  endpoints.membersPage,
  endpoints.hubPage,
  endpoints.newsPage,
]);

export const cacheEndpoints = new Set([
  endpoints.hubCallUsPage.split('?')[0] as string,
  endpoints.hubWebToCasePage.split('?')[0] as string,
  endpoints.hubFAQPage.split('?')[0] as string,
  endpoints.accountContactUs.split('?')[0] as string,
]);
