import {
  HeadlineStepperWrapper,
  StepperWrapper,
  StyledButton,
} from '../NumberStepper/NumberStepper.styles';
import { Icons } from '@components/library';
import { Typography } from '@wheelsup/wu-react-components';

const Stepper = ({
  count,
  setCount,
  limits,
}: {
  count: number;
  setCount: (count: number) => void;
  limits: {
    minValue: number;
    maxValue: number;
  };
}) => {
  const handleClick = (type: string) => {
    const countOperation =
      type === '+' ? count + 1 : count - 1;
    setCount(countOperation);
  };
  return (
    <HeadlineStepperWrapper>
      <StepperWrapper>
        <StyledButton
          onClick={() => handleClick('-')}
          disabled={count === limits.minValue}
          aria-label="-"
        >
          <Icons.Remove />
        </StyledButton>
        <Typography variant="body01">{count}</Typography>
        <StyledButton
          onClick={() => handleClick('+')}
          disabled={count === limits.maxValue}
          aria-label="+"
        >
          <Icons.Add />
        </StyledButton>
      </StepperWrapper>
    </HeadlineStepperWrapper>
  );
};

export default Stepper;
