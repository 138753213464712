export const getFormattedCurrency = (
  amount: {
    unit: string;
    symbol: string;
    amount: number;
  } | null | undefined,
  numDecimals = 2,
) => {
  // this crashes if we pass the wrong unit in, so i wrote a try catch block...
  try {
    const currencyFormatter = new Intl.NumberFormat(
      'en-US',
      {
        style: 'currency',
        currency: amount?.unit ?? 'USD',
        maximumFractionDigits: numDecimals,
        minimumFractionDigits: numDecimals,
      },
    );
    return currencyFormatter.format(Number(amount?.amount));
  } catch (err) {
    const currencyFormatter = new Intl.NumberFormat(
      'en-US',
      {
        style: 'currency',
        currency: 'USD',
      },
    );
    return currencyFormatter.format(Number(amount?.amount));
  }
};
