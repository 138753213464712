import {
  useContext,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import { useLocation } from 'react-router';
import {
  HeaderWrapper,
  ContainerWrapper,
  LinkLogo,
} from './Header.styles';
import { hostedImage } from '@constants/images/images.constants';
import { TopNavigation } from '@molecules';
import { ImageImgix } from '@atoms';
import { useViewport } from '@hooks';
import { BackgroundContext } from '@services/contextService/context';

const Header = () => {
  const { backgroundState } = useContext(BackgroundContext);
  const hasImage = backgroundState.background.hasImage;
  const { pathname } = useLocation();
  const isGeneralPurchaseProductPage =
    pathname === '/purchase-product';
  const [openMenu, setOpenMenu] = useState(false);
  const [isSticky, setIsSticky] = useState(false);
  const { width } = useViewport();
  gsap.registerPlugin(ScrollTrigger);

  useLayoutEffect(() => {
    ScrollTrigger.matchMedia({
      '(min-width: 1136px)': function () {
        gsap.to('#header', {
          scrollTrigger: {
            trigger: '#header',
            start: 1,
            end: '99999',
            scrub: true,
            toggleClass: 'sticky',
            invalidateOnRefresh: true,
            onEnter: () => setIsSticky(true),
            onLeaveBack: () => setIsSticky(false),
          },
        });
      },
    });
  }, []);

  const { blueText, className } = useMemo(() => {
    const blueText =
      width >= 1136 &&
      (pathname === '/signin' ||
        pathname.includes('delta-signup') ||
        pathname.includes('create-account'));

    let className = '';

    // Transitioning from /my-trips/<id> to /my-trips doesn't seem to update `hasImage` correctly.
    if (
      (!hasImage || pathname == '/my-trips') &&
      !isSticky
    ) {
      className = 'blueBackground';
    } else if (isSticky) {
      className = 'sticky';
    } else {
      className = 'transparent';
    }

    return { blueText, className };
  }, [width, isSticky, hasImage, pathname]);

  useLayoutEffect(() => {
    setIsSticky(false);
  }, [pathname]);

  const handleOpenMenu = (val: boolean) => {
    setOpenMenu(val);
  };

  return (
    <HeaderWrapper
      id="header"
      openMenu={openMenu}
      $isSticky={isSticky || !hasImage}
      $hasImage={hasImage}
      className={className}
    >
      <ContainerWrapper>
        <LinkLogo
          to="/"
          className="linkLogo"
          $isBlue={blueText}
          $isGeneralPurchaseProductPage={
            isGeneralPurchaseProductPage
          }
        >
          <ImageImgix
            src={
              blueText
                ? hostedImage.wheelsUpLogo
                : hostedImage.wheelsUpLogoWhite
            }
            alt="WheelsUp Logo"
            className="logo"
          />
        </LinkLogo>
        {isGeneralPurchaseProductPage ? null : (
          <TopNavigation
            isSticky={isSticky || !hasImage}
            openMenu={openMenu}
            blueText={blueText}
            setOpenMenu={handleOpenMenu}
          />
        )}
      </ContainerWrapper>
    </HeaderWrapper>
  );
};

export default Header;
