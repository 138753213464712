import HubReducer, {
  hubPanelSliceFactory,
} from '@features/Hub/HubSlice';
import confirmationPopUpReducer, {
  PopUpStatusReducer,
} from '@features/Fly/Confirmation/ConfirmationPopUpSlice';
import {
  dataSliceOptions,
  pageSliceOptions,
} from '@services/sliceFactoryService/sliceFactoryService.types';

import AccountSubpageSlice from '@features/Account/AccountSubpageSlice';
import AmexEligibilitySlice from '@features/PartnerProgram/AmexEligibilitySlice';
import { AppLoadingReducer } from './AppLoadingSlice';
import CardSnackBarReducer from '@components/molecules/CardSnackbar/CardSnackbarSlice';
import CarouselReducer from '@features/Dashboard/CarouselSlice';
import ErrorSlice from '@components/molecules/Error/ErrorSlice';
import FlySearchTripDetailsReducer from '@features/Fly/FlySearchTripDetailsSlice';
import FooterReducer from '@services/footerService/footerSlice';
import Handle401Reducer from '@services/apiService/responseInterceptors/handle401Slice';
import HandleUncaughtErrorReducer from '@services/apiService/responseInterceptors/handleUncaughtErrorSlice';
import MemberPopupReducer from '@features/Members/popupSlice';
import MembersSubpageSlice from '@features/Members/MemberSubpageSlice';
import MyTripsCancelSlice from '@features/MyTrips/MyTripsCancelSlice';
import SearchFlightReducer from '@features/Fly/searchFlightSlice';
import SubPagePassengerAddEditSlice from '@components/organisms/SubPagePassengerAddEdit/SubPagePassengerAddEdit.slice';
import SubPagePetAddEditSlice from '@components/organisms/SubPagePetAddEdit/SubPagePetAddEdit.slice';
import { bookOpportunity } from '@features/Fly/Confirmation/ConfirmationSlice';
import { combineReducers } from '@reduxjs/toolkit';
import { dataSliceFactory } from '@services/sliceFactoryService/requestSlice';
import expiredSessionPopUp from '@features/Fly/SessionSlice';
import { pageSliceFactory } from '@services/sliceFactoryService/sliceFactory';
import passengerSummarySlice from '@features/Passengers/PassengerSummarySlice';
import postBookingReducer from '@features/Fly/Confirmation/PostBookingSlice';
import paymentStatusReducer from '@features/Fly/Payment/PaymentStatusSlice';
import AircraftDetailsReducer from '@components/organisms/CardAircraftDetailsSubpage/AircraftDetailsSlice';
import SubPageFlightDealResultsReducer from '@components/organisms/SubPageFlightDealResults/SubPageFlightDealResults.slice';
import sessionLockSlice from '@services/sessionLockService/sessionLockSlice';
import AirmedReducer from '@features/AirMed/AirmedSlice';
import MyTripsCancelOfferReducer from '@features/MyTrips/MyTripsCancelOfferSlice';

export const passengerSelectionSliceInstance = dataSliceFactory(
  dataSliceOptions.passengerSelection,
);
export const passengerCreatePageSliceInstance = pageSliceFactory(
  pageSliceOptions.passengerCreate,
);
export const selectPassengersSliceInstance = pageSliceFactory(
  pageSliceOptions.selectPassengers,
);

export const flightSearchValidation = dataSliceFactory(
  dataSliceOptions.flightValidate,
);

export const newsDetailsSliceInstance = pageSliceFactory(
  pageSliceOptions.newsDetails,
);

export const flySearchResultsInstance = pageSliceFactory(
  pageSliceOptions.flySearchResults,
);

export const flySearchTripDetailsPageInstance = pageSliceFactory(
  pageSliceOptions.flySearchTripDetails,
);

const rootReducer = combineReducers({
  handle401: Handle401Reducer,
  handleUncaughtError: HandleUncaughtErrorReducer,
  hub: HubReducer,
  account: pageSliceFactory(pageSliceOptions.account)
    .mainReducer,
  passengerSummary: pageSliceFactory(
    pageSliceOptions.passengerSummary,
  ).mainReducer,
  petSummary: pageSliceFactory(pageSliceOptions.petSummary)
    .mainReducer,
  hubMain: pageSliceFactory(pageSliceOptions.hub)
    .mainReducer,
  hubPanel: hubPanelSliceFactory(dataSliceOptions.hubPanel),
  popupOpen: MemberPopupReducer,
  searchFlight: SearchFlightReducer,
  dashboard: pageSliceFactory(pageSliceOptions.dashboard)
    .mainReducer,
  members: pageSliceFactory(pageSliceOptions.members)
    .mainReducer,
  fly: pageSliceFactory(pageSliceOptions.fly).mainReducer,
  flyDeals: dataSliceFactory(dataSliceOptions.flyDeals)
    .mainReducer,
  demo: pageSliceFactory(pageSliceOptions.demo).mainReducer,
  progressiveProfile: CarouselReducer,
  flySearchResults: flySearchResultsInstance.mainReducer,
  petEdit: pageSliceFactory(pageSliceOptions.petEdit)
    .mainReducer,
  news: pageSliceFactory(pageSliceOptions.news).mainReducer,
  passengerSubpage: pageSliceFactory(
    pageSliceOptions.passengerPicker,
  ).mainReducer,
  flySearchTripDetails:
    flySearchTripDetailsPageInstance.mainReducer,
  searchTripDetails: FlySearchTripDetailsReducer,
  newsDetails: newsDetailsSliceInstance.mainReducer,
  passengerSummarySlice: passengerSummarySlice,
  membersSubpage: MembersSubpageSlice,
  appLoading: AppLoadingReducer,
  accountSubpage: AccountSubpageSlice.reducer,
  confirmation: pageSliceFactory(
    pageSliceOptions.confirmation,
  ).mainReducer,
  confirmationMarket: pageSliceFactory(
    pageSliceOptions.confirmationMarket,
  ).mainReducer,
  myTrip: pageSliceFactory(pageSliceOptions.myTrips)
    .mainReducer,
  myTripsDetails: pageSliceFactory(
    pageSliceOptions.myTripsDetails,
  ).mainReducer,
  quoteRequestedConfirmation: bookOpportunity(
    dataSliceOptions.bookOpportunity,
  ),
  searchValidation: flightSearchValidation.mainReducer,
  subPassengerAddEdit: SubPagePassengerAddEditSlice,
  subPetAddEdit: SubPagePetAddEditSlice,
  nearbyAirports: dataSliceFactory(
    dataSliceOptions.nearbyAirport,
  ).mainReducer,
  timePicker: dataSliceFactory(dataSliceOptions.timePicker)
    .mainReducer,
  passengerCreatePage:
    passengerCreatePageSliceInstance.mainReducer,
  selectPassengers:
    selectPassengersSliceInstance.mainReducer,
  datePicker: dataSliceFactory(dataSliceOptions.datePicker)
    .mainReducer,
  savedAirports: dataSliceFactory(
    dataSliceOptions.savedAirports,
  ).mainReducer,
  upcomingReservationTravelDetails: pageSliceFactory(
    pageSliceOptions.upcomingReservationTravelDetails,
  ).mainReducer,
  footerData: FooterReducer,
  expiredSession: expiredSessionPopUp,
  confirmationContactPopUp: PopUpStatusReducer,
  confirmationContactDetails: confirmationPopUpReducer,
  postBooking: postBookingReducer,
  payment: pageSliceFactory(pageSliceOptions.payment)
    .mainReducer,
  paymentStatus: paymentStatusReducer,
  selectPayment: dataSliceFactory(
    dataSliceOptions.selectPaymentRequest,
  ).mainReducer,
  selectAirmedPayment: dataSliceFactory(
    dataSliceOptions.selectAirmedPaymentRequest,
  ).mainReducer,
  addCreditCardPopUp: dataSliceFactory(
    dataSliceOptions.addCreditCardRequest,
  ).mainReducer,
  checkWire: dataSliceFactory(
    dataSliceOptions.checkWireRequest,
  ).mainReducer,
  myTripsCancel: MyTripsCancelSlice,
  error: ErrorSlice,
  supPagePassengerSelection:
    passengerSelectionSliceInstance.mainReducer,
  cardSnackBar: CardSnackBarReducer,
  flightLegItemLuggageInfo: dataSliceFactory(
    dataSliceOptions.flightLegLuggageItemInfo,
  ).mainReducer,
  amexEligibility: AmexEligibilitySlice,
  flightDeals: dataSliceFactory(
    dataSliceOptions.flightDeals,
  ).mainReducer,
  handleShowAircraftDetails: AircraftDetailsReducer,
  flightDetail: pageSliceFactory(
    pageSliceOptions.flightDetail,
  ).mainReducer,
  selectLuggage: dataSliceFactory(
    dataSliceOptions.selectLuggage,
  ).mainReducer,
  subPageFlightDealResults: SubPageFlightDealResultsReducer,
  sessionLock: sessionLockSlice,
  airmed: AirmedReducer,
  airmedPayment: pageSliceFactory(
    pageSliceOptions.airmedPayment,
  ).mainReducer,
  limitedTimeDeals: pageSliceFactory(
    pageSliceOptions.limitedTimeDeals,
  ).mainReducer,
  myTripsCancelOffer: MyTripsCancelOfferReducer,
  manageCreditCards: pageSliceFactory(
    pageSliceOptions.manageCreditCards,
  ).mainReducer,
  manageLuggagePage: pageSliceFactory(
    pageSliceOptions.manageLuggagePage,
  ).mainReducer,
});

export default rootReducer;
