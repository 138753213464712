import styled from 'styled-components';
import { Container } from '@components/library';
import { Button } from '@atoms';
import { theme } from '@constants/styles/theme.constants';
import { Typography } from '@wheelsup/wu-react-components';

export const FlightSearchSubPage = styled.div`
  min-height: 90vh;
`;

export const FlightSearchSubPageContainer = styled(
  Container,
)`
  background: white;
  border-radius: 20px;
  box-shadow: 0 2px 4px 1px rgba(0, 0, 0, 0.12);
  padding: 32px 40px !important;
  gap: 26px;
  display: flex;
  flex-direction: column;
  height: auto;

  @media (max-width: ${theme.breakpoints.values.sm}px) {
    padding: 30px 20px !important;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const StyledSubmitFlightSearch = styled(Button)<{
  height?: string;
}>`
  text-align: center;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 1.86px;
  line-height: 15px;
  height: ${({ height }) => (height ? height : '60px')};
  width: 200px;

  svg,
  img {
    width: 1rem;
    margin-right: 0.5rem;
  }

  @media (max-width: ${theme.breakpoints.values.sm}px) {
    width: 100%;
  }
`;

export const ErrorContainer = styled.div`
  display: flex;
  gap: 20px;
  margin-bottom: -1rem;
  width: 100%;

  > div {
    width: 100%;
  }
`;

export const SpacingErrorWrapper = styled.div`
  padding-left: 0px;
`;

export const LegContainerContainer = styled.div`
  display: grid;
  gap: 50;
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: ${theme.breakpoints.values.sm}px) {
    flex-direction: column;
  }
`;

export const StyledTypography = styled(Typography)`
  @media (max-width: ${theme.breakpoints.values.sm}px) {
    margin-bottom: 24px;
  }
`;
