import { paths } from '@routing/routerPaths';
import { IImgTitleCopyStepperData } from '../ImageTitleSubtitleStepperListItemView/ImageTitleSubtitleStepperListItemView.interfaces';

export function shouldShowLuggageInput(luggage: IImgTitleCopyStepperData): boolean {
    const isInCorrectLocation = window.location.pathname === paths.FLY_SEARCH_TRIP_DETAILS || window.location.pathname.includes('manage-luggage');
    const isCorrectLuggageType = luggage.data.codeValue === 'OVERSIZE' || luggage.data.codeValue === 'MISC';
    const hasLuggage = (luggage.data.stepper.defaultValue ?? 0) > 0;
    return isInCorrectLocation && isCorrectLuggageType && hasLuggage;
}

export function setLuggageErrorData(luggage: IImgTitleCopyStepperData, count: number): boolean {
    const isCorrectLuggageType = luggage.data.codeValue === 'OVERSIZE' || luggage.data.codeValue === 'MISC';
    const hasNoNote = !luggage.data.note;
    const hasCountGreaterThanZero = count > 0;

    return isCorrectLuggageType && hasNoNote && hasCountGreaterThanZero;
}

