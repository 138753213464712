import { CarouselListSlide } from '@atoms';
import { ICarouselList } from './CarouselList.interfaces';
import {
  CarouselListWrapperContainer,
  WrapperInner,
  WrapperInnerContainer,
} from './CarouselList.styles';
import { Elevation } from '@wheelsup/wu-react-components';
import { paths } from '@routing/routerPaths';

const CarouselList = ({ children }: ICarouselList) => {
  const isOnSearchTripDetails =
    window.location.pathname ===
    paths.FLY_SEARCH_TRIP_DETAILS;

  const activeSlides = Array.isArray(children) ? (
    children.map((card, index) => {
      const key = `${index}`;
      return (
        <CarouselListSlide key={key}>
          {card}
        </CarouselListSlide>
      );
    })
  ) : (
    <CarouselListSlide>{children}</CarouselListSlide>
  );

  return isOnSearchTripDetails ? (
    <Elevation
      data-name="CarouselList"
      variant="raisedLevel2"
      padding={0}
    >
      <WrapperInner>{activeSlides}</WrapperInner>
    </Elevation>
  ) : (
    <CarouselListWrapperContainer data-name="CarouselList">
      <WrapperInnerContainer>
        {activeSlides}
      </WrapperInnerContainer>
    </CarouselListWrapperContainer>
  );
};

export default CarouselList;
