import { ISubPageSelectPayment } from './SubPageSelectPayment.interfaces';
import {
  StyledSubPageCheckWireTransfer,
  StyledTitleContainer,
  StyledLine,
  StyledAddEditButton,
  StyledInput,
  StyledButtonContainer,
  StyledLabel,
} from './SubPageSelectPayment.styles';
import { typography } from '@constants/styles/typography.constants';
import { solidColors } from '@constants/styles/colors.constants';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { CustomRadio } from '@molecules';
import Typography from '@atoms/Typography/Typography';
import {
  Checkbox,
  Typography as SharedLibraryTypography,
} from '@wheelsup/wu-react-components';
import { useEffect, useState } from 'react';
import InputBase from '@atoms/InputBase';
import {
  setLocationDisableNext,
  setPaymentLocation,
  setSelectedPaymentMethods,
  setTotalCostAmount,
  updatePriceBreakdownRequest,
} from '@features/Fly/Payment/PaymentStatusSlice';
import { IKeyValue, IPayment } from '@components/types';
import { getAccessToken } from '@services/tokenService/token';
import { getBookingFlowData } from './SubPageSelectPayment.utils';
import { round } from 'lodash';
import { useFeatureFlag } from '@services/featureFlagService/featureFlagService.services';
import { FeatureFlags } from '@services/featureFlagService/constants';
import { useNavigate } from 'react-router-dom';

const BookingFlowSelectPayment = ({
  title,
  subtitle,
  totalCostCaption,
  totalCostAmount,
  totalCostValue,
  selectionType,
  paymentOptions,
  addCreditCardButton,
  checkOrWireTransferButton,
}: ISubPageSelectPayment) => {
  const dispatch = useAppDispatch();
  const use_native_manage_payment_methods = useFeatureFlag(
    FeatureFlags.USE_NATIVE_MANAGE_PAYMENT_METHODS,
  );
  const navigate = useNavigate();

  const {
    creditCardFeeCaption,
    creditCardFeeAmount,
    selectedCredits,
    selectedPaymentMethods,
  } = useAppSelector((state) => state.paymentStatus);

  const stateTotalCostAmount = useAppSelector(
    (state) => state.paymentStatus.totalCostAmount,
  );
  const stateTotalCostValue = useAppSelector(
    (state) => state.paymentStatus.totalCostValue,
  );

  const [
    localTotalCostAmount,
    setLocalTotalCostAmount,
  ] = useState('');
  const [
    localTotalCostValue,
    setLocalTotalCostValue,
  ] = useState(0);
  const [
    paymentOptionList,
    setPaymentOptionList,
  ] = useState<IPayment[]>([]);
  const [selectedFunds, setSelectedFunds] = useState<
    IPayment[]
  >([]);
  const [
    selectedCreditCards,
    setSelectedCreditCards,
  ] = useState<IPayment[]>([]);
  const [selectedSpecial, setSelectedSpecial] = useState<
    IPayment[]
  >([]);
  const [selectedAch, setSelectedAch] = useState<
    IPayment[]
  >([]);
  const [remaining, setRemaining] = useState(0);

  useEffect(() => {
    const preSelectedPayments = paymentOptions.filter(
      (option) => option.isPreselected,
    );
    if (preSelectedPayments.length <= 3) {
      const selectedFundClone: IPayment[] = [];
      const selectedCCClone: IPayment[] = [];
      const selectedAchClone: IPayment[] = [];
      const selectedSpecialClone: IPayment[] = [];
      preSelectedPayments.forEach((option) => {
        switch (option.paymentType) {
          case 'fund':
            selectedFundClone.push(option);
            break;
          case 'creditCard':
            selectedCCClone.push(option);
            break;
          case 'ach':
            selectedAchClone.push(option);
            break;
          default:
            selectedSpecialClone.push(option);
            break;
        }
      });
      setSelectedFunds(selectedFundClone);
      setSelectedCreditCards(selectedCCClone);
      setSelectedAch(selectedAchClone);
      setSelectedSpecial(selectedSpecialClone);

      setPaymentOptions(preSelectedPayments);
      setPaymentOptionList(paymentOptions);
    } else {
      const newPaymentOptionsList = paymentOptions.map(
        (option) => ({
          ...option,
          isPreselected: false,
          preselectedSplitValue: 0,
        }),
      );
      setPaymentOptionList(newPaymentOptionsList);
    }
  }, [paymentOptions]);

  useEffect(() => {
    setLocalTotalCostAmount(totalCostAmount);
    setLocalTotalCostValue(totalCostValue);
    dispatch(
      setTotalCostAmount({
        totalCostAmount: totalCostAmount,
        totalCostValue: totalCostValue,
      }),
    );
  }, [totalCostAmount, totalCostValue]);

  useEffect(() => {
    setLocalTotalCostAmount(stateTotalCostAmount || '');
    setLocalTotalCostValue(stateTotalCostValue || 0);

    let newRemaining = stateTotalCostValue;

    if (paymentOptionList.length > 0) {
      paymentOptionList.forEach((option) => {
        if (
          option.isPreselected &&
          option.paymentType !== 'atoFund'
        ) {
          if (option.paymentType === 'creditCard') {
            const tmp = newRemaining;
            newRemaining -= tmp;
            return {
              ...option,
              preselectedSplitValue: tmp,
            };
          }
          newRemaining -= option.preselectedSplitValue || 0;
        }
        return option;
      });
    }
    setRemaining(round(newRemaining, 0));
  }, [
    stateTotalCostAmount,
    stateTotalCostValue,
    paymentOptionList,
  ]);

  useEffect(() => {
    const notZero = !selectedPaymentMethods.some(
      (method) => (method.preselectedSplitValue ?? 0) <= 0,
    );
    if (
      remaining <= 0 &&
      selectedPaymentMethods.length > 0 &&
      notZero
    ) {
      dispatch(setLocationDisableNext(false));
    } else {
      dispatch(setLocationDisableNext(true));
    }
  }, [remaining, selectedPaymentMethods]);

  const isDisabled = (paymentOption: IPayment) => {
    if (selectionType === 'radioButton') return false;
    else
      switch (paymentOption.paymentType) {
        case 'fund':
          return (
            !(
              selectedSpecial.length <= 0 &&
              selectedFunds.length <= 1
            ) && !paymentOption.isPreselected
          );
        case 'creditCard':
          return (
            (!(
              (selectedSpecial.length <= 0 ||
                remaining > 0) &&
              selectedCreditCards.length === 0
            ) &&
              !paymentOption.isPreselected) ||
            selectedAch.length > 0
          );
        case 'executiveHours':
          return (
            !(
              selectedSpecial.length <= 0 &&
              selectedFunds.length <= 0
            ) && !paymentOption.isPreselected
          );
        case 'atoFund':
          return (
            !(
              selectedSpecial.length <= 0 &&
              selectedFunds.length <= 0
            ) && !paymentOption.isPreselected
          );
        case 'ach':
          return (
            (!(
              selectedSpecial.length <= 0 &&
              selectedAch.length <= 0
            ) &&
              !paymentOption.isPreselected) ||
            selectedCreditCards.length > 0
          );
        default:
          return false;
      }
  };

  const setPaymentOptions = (
    newSelectedPaymentList: IPayment[],
  ) => {
    setPaymentOptionList(newSelectedPaymentList);
    const newSelectedPaymentMethods = newSelectedPaymentList.filter(
      (payment: IPayment) => payment.isPreselected,
    );
    let newRemaining = localTotalCostValue;
    newSelectedPaymentMethods.forEach((option) => {
      if (option.isPreselected) {
        newRemaining -= option.preselectedSplitValue || 0;
      }
    });

    setRemaining(round(newRemaining, 0));

    const newBookingFlowData = getBookingFlowData();
    dispatch(
      setSelectedPaymentMethods(newSelectedPaymentMethods),
    );

    const resPayload = {
      ...newBookingFlowData,
      selectedPayments: [
        ...selectedCredits,
        ...newSelectedPaymentMethods,
      ],
    };
    dispatch(
      updatePriceBreakdownRequest({
        token: getAccessToken(),
        resPayload: resPayload,
      }),
    );
  };

  const handleFund = (
    paymentOption: IPayment,
    addRemoveFlag: boolean,
  ) => {
    const preSelectedPaymentOptions = paymentOptionList.filter(
      (option) => {
        return (
          option.isPreselected &&
          option.id !== paymentOption.id
        );
      },
    );
    let newPreselectedSplitValue = 0;
    let newRemaining = localTotalCostValue;
    let preSelectedFund = paymentOption;
    let preSelectedCreditCard = paymentOption;
    let preSelectedAch = paymentOption;

    if (addRemoveFlag) {
      let canAdd = true;
      selectedFunds.forEach((option) => {
        if (option.id === paymentOption.id) {
          canAdd = false;
        }
      });
      canAdd &&
        setSelectedFunds([...selectedFunds, paymentOption]);

      if (preSelectedPaymentOptions.length === 0) {
        if (paymentOption.preselectedSplitValue) {
          newPreselectedSplitValue =
            paymentOption.preselectedSplitValue;
          newRemaining = 0;
        } else if (paymentOption.balance) {
          if (
            paymentOption.balance >= localTotalCostValue
          ) {
            newPreselectedSplitValue = localTotalCostValue;
            newRemaining = 0;
          } else {
            newPreselectedSplitValue =
              paymentOption.balance;
            newRemaining =
              localTotalCostValue - paymentOption.balance;
          }
        }
      } else if (preSelectedPaymentOptions.length === 1) {
        if (
          preSelectedPaymentOptions[0].paymentType ===
            'creditCard' ||
          preSelectedPaymentOptions[0].paymentType === 'ach'
        ) {
          if (
            preSelectedPaymentOptions[0].paymentType ===
            'creditCard'
          ) {
            preSelectedCreditCard = selectedCreditCards[0];
          }
          if (
            preSelectedPaymentOptions[0].paymentType ===
            'ach'
          ) {
            preSelectedAch = selectedAch[0];
          }
          // This checks if the option has a balance, if it does, then should be a valid fund
          if (paymentOption.balance) {
            // If the balance is greater than or equal that the total cost, the total cost should be assigned to the fund
            if (
              paymentOption.balance >= localTotalCostValue
            ) {
              const tmpPreselectedSplitValue =
                (paymentOption.preselectedSplitValue ||
                  0) <= paymentOption.balance
                  ? paymentOption.preselectedSplitValue || 0
                  : paymentOption.balance || 0;
              newRemaining =
                localTotalCostValue -
                tmpPreselectedSplitValue;
              newPreselectedSplitValue = tmpPreselectedSplitValue;
            } else {
              // If the balance is less than the total cost, the total balance should be selected and the CC amound updated
              const tmp =
                paymentOption.preselectedSplitValue === 0
                  ? paymentOption.balance
                  : paymentOption.preselectedSplitValue ||
                    0;
              newPreselectedSplitValue =
                tmp <= paymentOption.balance
                  ? tmp
                  : paymentOption.balance;
              newRemaining =
                localTotalCostValue -
                newPreselectedSplitValue;
            }
          }
        } else {
          if (
            // This checks if the value of the non-focused payment method is greater than or equal to the total cost
            (preSelectedPaymentOptions[0]
              .preselectedSplitValue || 0) >=
            localTotalCostValue
          ) {
            // You land here when adding a second payment for the first time and changing the value of new payment method
            newPreselectedSplitValue =
              paymentOption.preselectedSplitValue || 0;
            newRemaining =
              localTotalCostValue -
              newPreselectedSplitValue;
          } else {
            // You land here when changing the value of either of 2 payment methods that are funds
            if (paymentOption.balance) {
              if (
                // Checks that the balance of the payment method is greater than or equal to the total cost minus the value of the other payment method
                paymentOption.balance >=
                localTotalCostValue -
                  (preSelectedPaymentOptions[0]
                    .preselectedSplitValue || 0)
              ) {
                newPreselectedSplitValue =
                  paymentOption.preselectedSplitValue || 0;
                const currBalance =
                  preSelectedPaymentOptions[0].balance || 0;
                newRemaining =
                  localTotalCostValue -
                    newPreselectedSplitValue <=
                  currBalance
                    ? localTotalCostValue -
                      newPreselectedSplitValue
                    : currBalance;
              } else {
                // This will add the new payment method at the max balance of the fund
                newPreselectedSplitValue =
                  paymentOption.balance;
                newRemaining =
                  preSelectedPaymentOptions[0]
                    .preselectedSplitValue || 0;
              }
            }
          }
        }
      } else if (preSelectedPaymentOptions.length === 2) {
        preSelectedPaymentOptions.forEach((option) => {
          if (option.paymentType === 'fund') {
            preSelectedFund = option;
          } else if (
            preSelectedPaymentOptions[0].paymentType ===
            'creditCard'
          ) {
            preSelectedCreditCard = option;
          } else if (
            preSelectedPaymentOptions[0].paymentType ===
            'ach'
          ) {
            preSelectedAch = option;
          }
        });
        if (paymentOption.preselectedSplitValue) {
          newPreselectedSplitValue =
            paymentOption.preselectedSplitValue <=
            (paymentOption.balance || 0)
              ? paymentOption.preselectedSplitValue
              : paymentOption.balance || 0;
          const newTotalCost =
            localTotalCostValue - newPreselectedSplitValue;
          if (
            (preSelectedFund.balance || 0) >=
            newTotalCost / 2
          ) {
            preSelectedFund = {
              ...preSelectedFund,
              preselectedSplitValue: newTotalCost / 2,
            };
            if (
              preSelectedPaymentOptions[0].paymentType ===
              'creditCard'
            ) {
              preSelectedCreditCard = {
                ...preSelectedCreditCard,
                preselectedSplitValue: newTotalCost / 2,
              };
            } else if (
              preSelectedPaymentOptions[0].paymentType ===
              'ach'
            ) {
              preSelectedAch = {
                ...preSelectedAch,
                preselectedSplitValue: newTotalCost / 2,
              };
            }
            newRemaining = newTotalCost / 2;
          } else {
            preSelectedFund = {
              ...preSelectedFund,
              preselectedSplitValue:
                preSelectedFund.balance,
            };
            if (
              preSelectedPaymentOptions[0].paymentType ===
              'creditCard'
            ) {
              preSelectedCreditCard = {
                ...preSelectedCreditCard,
                preselectedSplitValue:
                  newTotalCost -
                  (preSelectedFund.balance || 0),
              };
            } else if (
              preSelectedPaymentOptions[0].paymentType ===
              'ach'
            ) {
              preSelectedAch = {
                ...preSelectedAch,
                preselectedSplitValue:
                  newTotalCost -
                  (preSelectedFund.balance || 0),
              };
            }
            newRemaining =
              newTotalCost - (preSelectedFund.balance || 0);
          }
        } else {
          // You land here when you have 2 selected payment methods and then select a third
          if (
            (preSelectedFund.balance || 0) >=
            localTotalCostValue / 2
          ) {
            preSelectedFund = {
              ...preSelectedFund,
              preselectedSplitValue:
                localTotalCostValue / 2,
            };
            if (
              preSelectedPaymentOptions[0].paymentType ===
              'creditCard'
            ) {
              preSelectedCreditCard = {
                ...preSelectedCreditCard,
                preselectedSplitValue:
                  localTotalCostValue / 2,
              };
            } else if (
              preSelectedPaymentOptions[0].paymentType ===
              'ach'
            ) {
              preSelectedAch = {
                ...preSelectedAch,
                preselectedSplitValue:
                  localTotalCostValue / 2,
              };
            }
            newRemaining = localTotalCostValue / 2;
          } else {
            preSelectedFund = {
              ...preSelectedFund,
              preselectedSplitValue:
                preSelectedFund.balance,
            };
            if (
              preSelectedPaymentOptions[0].paymentType ===
              'creditCard'
            ) {
              preSelectedCreditCard = {
                ...preSelectedCreditCard,
                preselectedSplitValue:
                  localTotalCostValue -
                  (preSelectedFund.balance || 0),
              };
            } else if (
              preSelectedPaymentOptions[0].paymentType ===
              'ach'
            ) {
              preSelectedAch = {
                ...preSelectedAch,
                preselectedSplitValue:
                  localTotalCostValue -
                  (preSelectedFund.balance || 0),
              };
            }
            newRemaining =
              localTotalCostValue -
              (preSelectedFund.balance || 0);
          }
        }
      }
    } else {
      {
        const newSelectedFunds = selectedFunds.filter(
          (option) => option.id !== paymentOption.id,
        );
        setSelectedFunds(newSelectedFunds);
        newPreselectedSplitValue = 0;

        if (preSelectedPaymentOptions.length === 1) {
          if (
            preSelectedPaymentOptions[0].paymentType ===
            'fund'
          ) {
            if (
              (preSelectedPaymentOptions[0].balance || 0) >=
              localTotalCostValue
            ) {
              newRemaining = localTotalCostValue;
            } else {
              newRemaining =
                localTotalCostValue -
                (preSelectedPaymentOptions[0].balance || 0);
            }
          } else {
            if (
              preSelectedPaymentOptions[0].paymentType ===
              'creditCard'
            ) {
              preSelectedCreditCard =
                selectedCreditCards[0];
            } else if (
              preSelectedPaymentOptions[0].paymentType ===
              'ach'
            ) {
              preSelectedAch = selectedAch[0];
            }
            newRemaining = localTotalCostValue;
          }
        } else if (preSelectedPaymentOptions.length === 2) {
          preSelectedPaymentOptions.forEach((option) => {
            if (option.paymentType === 'fund') {
              preSelectedFund = option;
            } else if (
              option.paymentType === 'creditCard'
            ) {
              preSelectedCreditCard = option;
            } else if (option.paymentType === 'ach') {
              preSelectedAch = option;
            }
          });
          if (
            (preSelectedFund.balance || 0) >=
            localTotalCostValue / 2
          ) {
            preSelectedFund = {
              ...preSelectedFund,
              preselectedSplitValue:
                localTotalCostValue / 2,
            };
            if (
              preSelectedPaymentOptions[0].paymentType ===
              'creditCard'
            ) {
              preSelectedCreditCard = {
                ...preSelectedCreditCard,
                preselectedSplitValue:
                  localTotalCostValue / 2,
              };
            } else if (
              preSelectedPaymentOptions[0].paymentType ===
              'ach'
            ) {
              preSelectedAch = {
                ...preSelectedAch,
                preselectedSplitValue:
                  localTotalCostValue / 2,
              };
            }
            newRemaining = localTotalCostValue / 2;
          } else {
            preSelectedFund = {
              ...preSelectedFund,
              preselectedSplitValue:
                preSelectedFund.balance,
            };
            if (
              preSelectedPaymentOptions[0].paymentType ===
              'creditCard'
            ) {
              preSelectedCreditCard = {
                ...preSelectedCreditCard,
                preselectedSplitValue:
                  localTotalCostValue -
                  (preSelectedFund.balance || 0),
              };
            } else if (
              preSelectedPaymentOptions[0].paymentType ===
              'ach'
            ) {
              preSelectedAch = {
                ...preSelectedAch,
                preselectedSplitValue:
                  localTotalCostValue -
                  (preSelectedFund.balance || 0),
              };
            }
            newRemaining =
              localTotalCostValue -
              (preSelectedFund.balance || 0);
          }
        }
      }
    }

    const newSelectedPayment = paymentOptionList.map(
      (mapPaymentOption) => {
        if (mapPaymentOption.id === paymentOption.id) {
          return {
            ...paymentOption,
            isPreselected: addRemoveFlag,
            preselectedSplitValue: newPreselectedSplitValue,
          };
        } else if (
          mapPaymentOption.isPreselected &&
          mapPaymentOption.id !== paymentOption.id
        ) {
          if (
            mapPaymentOption.paymentType === 'creditCard' &&
            preSelectedPaymentOptions.length <= 2
          ) {
            return {
              ...preSelectedCreditCard,
              preselectedSplitValue: newRemaining,
              isPreselected: true,
            };
          } else if (
            mapPaymentOption.paymentType === 'ach' &&
            preSelectedPaymentOptions.length <= 2
          ) {
            return {
              ...preSelectedAch,
              preselectedSplitValue: newRemaining,
              isPreselected: true,
            };
          } else if (
            mapPaymentOption.paymentType === 'fund' &&
            preSelectedPaymentOptions.length === 2
          ) {
            return preSelectedFund;
          } else {
            return {
              ...mapPaymentOption,
              isPreselected: newRemaining >= 0,
              preselectedSplitValue: newRemaining,
            };
          }
        } else {
          return mapPaymentOption;
        }
      },
    );
    setPaymentOptions(newSelectedPayment);
  };

  const handleCreditCard = (
    paymentOption: IPayment,
    addRemoveFlag: boolean,
  ) => {
    const preSelectedPaymentOptions = paymentOptionList.filter(
      (option) =>
        option.isPreselected &&
        option.id !== paymentOption.id,
    );
    let newPreselectedSplitValue = 0;
    let newRemaining = localTotalCostValue;
    let fund1: IPayment;
    let fund2: IPayment;

    if (addRemoveFlag) {
      setSelectedCreditCards([paymentOption]);

      if (preSelectedPaymentOptions.length === 0) {
        newPreselectedSplitValue =
          paymentOption.preselectedSplitValue ||
          localTotalCostValue;
        newRemaining = 0;
      } else if (preSelectedPaymentOptions.length === 1) {
        if (
          preSelectedPaymentOptions[0].paymentType ===
          'atoFund'
        ) {
          newPreselectedSplitValue = localTotalCostValue;
          newRemaining =
            preSelectedPaymentOptions[0]
              .preselectedSplitValue || 0;
        } else {
          if (paymentOption.preselectedSplitValue) {
            newPreselectedSplitValue =
              paymentOption.preselectedSplitValue;
            if (
              (preSelectedPaymentOptions[0]
                .preselectedSplitValue || 0) >=
              localTotalCostValue -
                paymentOption.preselectedSplitValue
            ) {
              newRemaining =
                localTotalCostValue -
                paymentOption.preselectedSplitValue;
            } else {
              // properly sets the newRemaining value
              newRemaining =
                totalCostValue -
                paymentOption.preselectedSplitValue;
            }
          } else {
            newPreselectedSplitValue =
              localTotalCostValue -
              (preSelectedPaymentOptions[0]
                .preselectedSplitValue || 0);
            newRemaining =
              preSelectedPaymentOptions[0]
                .preselectedSplitValue || 0;
          }
        }
      } else if (preSelectedPaymentOptions.length === 2) {
        if (paymentOption.preselectedSplitValue) {
          newPreselectedSplitValue =
            paymentOption.preselectedSplitValue;
          fund1 = preSelectedPaymentOptions[0];
          fund2 = preSelectedPaymentOptions[1];
          const newTotalCost =
            localTotalCostValue - newPreselectedSplitValue;
          if (
            (fund1.balance || 0) >= newTotalCost / 2 &&
            (fund2.balance || 0) >= newTotalCost / 2
          ) {
            fund1 = {
              ...fund1,
              preselectedSplitValue: newTotalCost / 2,
            };
            fund2 = {
              ...fund2,
              preselectedSplitValue: newTotalCost / 2,
            };
          } else if (
            (fund1.balance || 0) >= newTotalCost / 2 ||
            (fund2.balance || 0) >= newTotalCost / 2
          ) {
            if ((fund1.balance || 0) < newTotalCost / 2) {
              fund1 = {
                ...fund1,
                preselectedSplitValue: fund1.balance,
              };
              fund2 = {
                ...fund2,
                preselectedSplitValue:
                  newTotalCost - (fund1.balance || 0),
              };
            } else if (
              (fund2.balance || 0) <
              newTotalCost / 2
            ) {
              fund2 = {
                ...fund2,
                preselectedSplitValue: fund2.balance,
              };
              fund1 = {
                ...fund1,
                preselectedSplitValue:
                  newTotalCost - (fund2.balance || 0),
              };
            }
          } else {
            fund1 = {
              ...fund1,
              preselectedSplitValue: fund1.balance,
            };
            fund2 = {
              ...fund2,
              preselectedSplitValue: fund2.balance,
            };
          }
        } else {
          preSelectedPaymentOptions.map((option) => {
            newPreselectedSplitValue =
              newPreselectedSplitValue +
              (option.preselectedSplitValue || 0);
          });
          newPreselectedSplitValue =
            localTotalCostValue - newPreselectedSplitValue;
        }
      }
    } else {
      setSelectedCreditCards([]);
      newPreselectedSplitValue = 0;

      if (preSelectedPaymentOptions.length === 1) {
        if (
          (preSelectedPaymentOptions[0].balance || 0) >=
          localTotalCostValue
        ) {
          newRemaining = localTotalCostValue;
        } else {
          newRemaining =
            preSelectedPaymentOptions[0].balance || 0;
        }
      } else if (preSelectedPaymentOptions.length === 2) {
        fund1 = preSelectedPaymentOptions[0];
        fund2 = preSelectedPaymentOptions[1];
        if (
          (fund1.balance || 0) >= localTotalCostValue / 2 &&
          (fund2.balance || 0) >= localTotalCostValue / 2
        ) {
          fund1 = {
            ...fund1,
            preselectedSplitValue: localTotalCostValue / 2,
          };
          fund2 = {
            ...fund2,
            preselectedSplitValue: localTotalCostValue / 2,
          };
        } else if (
          (fund1.balance || 0) >= localTotalCostValue / 2 ||
          (fund2.balance || 0) >= localTotalCostValue / 2
        ) {
          if (
            (fund1.balance || 0) <
              localTotalCostValue / 2 &&
            (fund2.balance || 0) >=
              localTotalCostValue - (fund1.balance || 0)
          ) {
            fund1 = {
              ...fund1,
              preselectedSplitValue: fund1.balance,
            };
            fund2 = {
              ...fund2,
              preselectedSplitValue:
                localTotalCostValue - (fund1.balance || 0),
            };
          } else if (
            (fund2.balance || 0) <
              localTotalCostValue / 2 &&
            (fund1.balance || 0) >=
              localTotalCostValue - (fund2.balance || 0)
          ) {
            fund2 = {
              ...fund2,
              preselectedSplitValue: fund2.balance,
            };
            fund1 = {
              ...fund1,
              preselectedSplitValue:
                localTotalCostValue - (fund2.balance || 0),
            };
          } else {
            fund1 = {
              ...fund1,
              preselectedSplitValue: fund1.balance,
            };
            fund2 = {
              ...fund2,
              preselectedSplitValue: fund2.balance,
            };
          }
        } else {
          fund1 = {
            ...fund1,
            preselectedSplitValue: fund1.balance,
          };
          fund2 = {
            ...fund2,
            preselectedSplitValue: fund2.balance,
          };
        }
      }
    }

    const newSelectedPayment = paymentOptionList.map(
      (mapPaymentOption) => {
        if (mapPaymentOption.id === paymentOption.id) {
          return {
            ...paymentOption,
            isPreselected: addRemoveFlag,
            preselectedSplitValue: newPreselectedSplitValue,
          };
        } else if (
          mapPaymentOption.isPreselected &&
          mapPaymentOption.id !== paymentOption.id
        ) {
          if (preSelectedPaymentOptions.length !== 2) {
            return {
              ...mapPaymentOption,
              preselectedSplitValue:
                newRemaining <=
                (mapPaymentOption.balance || 0)
                  ? newRemaining
                  : mapPaymentOption.balance || 0,
            };
          } else {
            if (fund1 && mapPaymentOption.id === fund1.id) {
              return fund1;
            } else if (
              fund2 &&
              mapPaymentOption.id === fund2.id
            ) {
              return fund2;
            } else {
              return mapPaymentOption;
            }
          }
        } else {
          return mapPaymentOption;
        }
      },
    );
    setPaymentOptions(newSelectedPayment);
  };

  const handleAch = (
    paymentOption: IPayment,
    addRemoveFlag: boolean,
  ) => {
    const preSelectedPaymentOptions = paymentOptionList.filter(
      (option) =>
        option.isPreselected &&
        option.id !== paymentOption.id,
    );
    let newPreselectedSplitValue = 0;
    let newRemaining = localTotalCostValue;
    let fund1: IPayment;
    let fund2: IPayment;

    if (addRemoveFlag) {
      // Adding ach as a selected payment method
      setSelectedAch([paymentOption]);

      if (preSelectedPaymentOptions.length === 0) {
        // You land here when there are no payment methods selected
        newPreselectedSplitValue =
          paymentOption.preselectedSplitValue ||
          localTotalCostValue;
        newRemaining = 0;
      } else if (preSelectedPaymentOptions.length === 1) {
        // You land here when there is 1 payment methods selected
        if (
          preSelectedPaymentOptions[0].paymentType ===
          'atoFund'
        ) {
          // We are splitting with a fund here
          newPreselectedSplitValue = localTotalCostValue;
          newRemaining =
            preSelectedPaymentOptions[0]
              .preselectedSplitValue || 0;
        } else {
          if (paymentOption.preselectedSplitValue) {
            newPreselectedSplitValue =
              paymentOption.preselectedSplitValue;

            // below determines the final remaining value after splitting if a splitValue already exists
            if (
              (preSelectedPaymentOptions[0]
                .preselectedSplitValue || 0) >=
              localTotalCostValue -
                paymentOption.preselectedSplitValue
            ) {
              newRemaining =
                localTotalCostValue -
                paymentOption.preselectedSplitValue;
            } else {
              // properly sets the newRemaining value
              newRemaining =
                totalCostValue -
                paymentOption.preselectedSplitValue;
            }
          } else {
            // You land here when there is no split value for the payment method
            newPreselectedSplitValue =
              localTotalCostValue -
              (preSelectedPaymentOptions[0]
                .preselectedSplitValue || 0);
            newRemaining =
              preSelectedPaymentOptions[0]
                .preselectedSplitValue || 0;
          }
        }
      } else if (preSelectedPaymentOptions.length === 2) {
        // You land here when there are 2 payment methods selected
        if (paymentOption.preselectedSplitValue) {
          newPreselectedSplitValue =
            paymentOption.preselectedSplitValue;
          fund1 = preSelectedPaymentOptions[0];
          fund2 = preSelectedPaymentOptions[1];
          const newTotalCost =
            localTotalCostValue - newPreselectedSplitValue;
          if (
            (fund1.balance || 0) >= newTotalCost / 2 &&
            (fund2.balance || 0) >= newTotalCost / 2
          ) {
            fund1 = {
              ...fund1,
              preselectedSplitValue: newTotalCost / 2,
            };
            fund2 = {
              ...fund2,
              preselectedSplitValue: newTotalCost / 2,
            };
          } else if (
            (fund1.balance || 0) >= newTotalCost / 2 ||
            (fund2.balance || 0) >= newTotalCost / 2
          ) {
            // this else if handles some fund splitting cases
            if ((fund1.balance || 0) < newTotalCost / 2) {
              fund1 = {
                ...fund1,
                preselectedSplitValue: fund1.balance,
              };
              fund2 = {
                ...fund2,
                preselectedSplitValue:
                  newTotalCost - (fund1.balance || 0),
              };
            } else if (
              (fund2.balance || 0) <
              newTotalCost / 2
            ) {
              fund2 = {
                ...fund2,
                preselectedSplitValue: fund2.balance,
              };
              fund1 = {
                ...fund1,
                preselectedSplitValue:
                  newTotalCost - (fund2.balance || 0),
              };
            }
          } else {
            fund1 = {
              ...fund1,
              preselectedSplitValue: fund1.balance,
            };
            fund2 = {
              ...fund2,
              preselectedSplitValue: fund2.balance,
            };
          }
        } else {
          preSelectedPaymentOptions.map((option) => {
            newPreselectedSplitValue =
              newPreselectedSplitValue +
              (option.preselectedSplitValue || 0);
          });
          newPreselectedSplitValue =
            localTotalCostValue - newPreselectedSplitValue;
        }
      }
    } else {
      // This is when we are removing a selected ach
      setSelectedAch([]);
      newPreselectedSplitValue = 0;

      if (preSelectedPaymentOptions.length === 1) {
        if (
          (preSelectedPaymentOptions[0].balance || 0) >=
          localTotalCostValue
        ) {
          newRemaining = localTotalCostValue;
        } else {
          newRemaining =
            preSelectedPaymentOptions[0].balance || 0;
        }
      } else if (preSelectedPaymentOptions.length === 2) {
        fund1 = preSelectedPaymentOptions[0];
        fund2 = preSelectedPaymentOptions[1];
        if (
          (fund1.balance || 0) >= localTotalCostValue / 2 &&
          (fund2.balance || 0) >= localTotalCostValue / 2
        ) {
          fund1 = {
            ...fund1,
            preselectedSplitValue: localTotalCostValue / 2,
          };
          fund2 = {
            ...fund2,
            preselectedSplitValue: localTotalCostValue / 2,
          };
        } else if (
          (fund1.balance || 0) >= localTotalCostValue / 2 ||
          (fund2.balance || 0) >= localTotalCostValue / 2
        ) {
          if (
            (fund1.balance || 0) <
              localTotalCostValue / 2 &&
            (fund2.balance || 0) >=
              localTotalCostValue - (fund1.balance || 0)
          ) {
            fund1 = {
              ...fund1,
              preselectedSplitValue: fund1.balance,
            };
            fund2 = {
              ...fund2,
              preselectedSplitValue:
                localTotalCostValue - (fund1.balance || 0),
            };
          } else if (
            (fund2.balance || 0) <
              localTotalCostValue / 2 &&
            (fund1.balance || 0) >=
              localTotalCostValue - (fund2.balance || 0)
          ) {
            fund2 = {
              ...fund2,
              preselectedSplitValue: fund2.balance,
            };
            fund1 = {
              ...fund1,
              preselectedSplitValue:
                localTotalCostValue - (fund2.balance || 0),
            };
          } else {
            fund1 = {
              ...fund1,
              preselectedSplitValue: fund1.balance,
            };
            fund2 = {
              ...fund2,
              preselectedSplitValue: fund2.balance,
            };
          }
        } else {
          fund1 = {
            ...fund1,
            preselectedSplitValue: fund1.balance,
          };
          fund2 = {
            ...fund2,
            preselectedSplitValue: fund2.balance,
          };
        }
      }
    }

    const newSelectedPayment = paymentOptionList.map(
      (mapPaymentOption) => {
        if (mapPaymentOption.id === paymentOption.id) {
          return {
            ...paymentOption,
            isPreselected: addRemoveFlag,
            preselectedSplitValue: newPreselectedSplitValue,
          };
        } else if (
          mapPaymentOption.isPreselected &&
          mapPaymentOption.id !== paymentOption.id
        ) {
          if (preSelectedPaymentOptions.length !== 2) {
            return {
              ...mapPaymentOption,
              preselectedSplitValue:
                newRemaining <=
                (mapPaymentOption.balance || 0)
                  ? newRemaining
                  : mapPaymentOption.balance || 0,
            };
          } else {
            if (fund1 && mapPaymentOption.id === fund1.id) {
              return fund1;
            } else if (
              fund2 &&
              mapPaymentOption.id === fund2.id
            ) {
              return fund2;
            } else {
              return mapPaymentOption;
            }
          }
        } else {
          return mapPaymentOption;
        }
      },
    );
    setPaymentOptions(newSelectedPayment);
  };

  const handleATOFund = (
    paymentOption: IPayment,
    addRemoveFlag: boolean,
  ) => {
    let newPreselectedSplitValue = 0;
    let newRemaining = localTotalCostValue;
    if (addRemoveFlag) {
      setSelectedSpecial([paymentOption]);
      if (paymentOption.balance) {
        if (paymentOption.balance >= localTotalCostValue) {
          newPreselectedSplitValue = localTotalCostValue;
          newRemaining = 0;
        } else {
          newPreselectedSplitValue = paymentOption.balance;
          newRemaining =
            localTotalCostValue - paymentOption.balance;
        }
      }
    } else {
      setSelectedSpecial([]);
      newPreselectedSplitValue = 0;
    }

    const newSelectedPayment = paymentOptionList.map(
      (mapPaymentOption) => {
        if (mapPaymentOption.id === paymentOption.id) {
          return {
            ...paymentOption,
            isPreselected: addRemoveFlag,
            preselectedSplitValue: newPreselectedSplitValue,
          };
        } else if (
          mapPaymentOption.isPreselected &&
          mapPaymentOption.id !== paymentOption.id
        ) {
          return {
            ...mapPaymentOption,
            preselectedSplitValue: newRemaining,
          };
        } else {
          return mapPaymentOption;
        }
      },
    );
    setPaymentOptions(newSelectedPayment);
  };

  const handleSelectedPaymentOptionsChange = (
    paymentOption: IPayment,
    isChecked: boolean,
  ) => {
    if (selectionType === 'radioButton') {
      const newSelectedPayment = paymentOptionList.map(
        (mapPaymentOption) => {
          if (mapPaymentOption.id === paymentOption.id) {
            return {
              ...paymentOption,
              isPreselected: isChecked,
              preselectedSplitValue: totalCostValue,
            };
          } else {
            return {
              ...mapPaymentOption,
              isPreselected: false,
            };
          }
        },
      );

      setPaymentOptions(newSelectedPayment);

      if (isChecked) {
        if (paymentOption.paymentType === 'creditCard')
          setSelectedCreditCards([paymentOption]);
        else if (paymentOption.paymentType === 'ach')
          setSelectedAch([paymentOption]);
      }
    } else {
      switch (paymentOption.paymentType) {
        case 'fund':
          handleFund(paymentOption, isChecked);
          break;
        case 'creditCard':
          handleCreditCard(paymentOption, isChecked);
          break;
        case 'atoFund':
          handleATOFund(paymentOption, isChecked);
          break;
        case 'ach':
          handleAch(paymentOption, isChecked);
          break;
      }
    }
  };

  const handleInput = (
    value: string,
    paymentOption: IPayment,
  ) => {
    const preselectedSplitValue = Number(value);
    const newSelectedPayment = paymentOptionList.map(
      (mapPaymentOption) => {
        if (mapPaymentOption.id === paymentOption.id) {
          return {
            ...paymentOption,
            preselectedSplitValue: preselectedSplitValue,
          };
        } else {
          return mapPaymentOption;
        }
      },
    );
    setPaymentOptions(newSelectedPayment);
  };

  const getFormattedInputValue = (value: string) => {
    const formattedValue = value.match(
      /^-?\d+(?:\.\d{0,4})?/,
    );
    return formattedValue !== null
      ? Number(formattedValue[0]).toFixed(2)
      : '0';
  };

  const getLabel = (paymentOption: IPayment) => {
    return (
      <StyledLabel
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
        }}
      >
        <Typography
          variant={typography.body1}
          color={
            isDisabled(paymentOption)
              ? solidColors.darkGray
              : solidColors.midnight
          }
        >
          {paymentOption.displayValue1}
        </Typography>
        <Typography
          variant={typography.body2}
          color={
            paymentOption.isPreselected &&
            (paymentOption.paymentType === 'fund' ||
              paymentOption.paymentType === 'atoFund') &&
            remaining > 0
              ? solidColors.lava
              : solidColors.midnight
          }
        >
          {paymentOption.isPreselected &&
          (paymentOption.paymentType === 'fund' ||
            paymentOption.paymentType === 'atoFund') &&
          remaining > 0
            ? `Insufficient fund: ${paymentOption.displayValue2}`
            : paymentOption.displayValue2}
        </Typography>
        {paymentOption.fundExpirationDate ? (
          <SharedLibraryTypography variant="body02">
            {paymentOption.fundExpirationDate}
          </SharedLibraryTypography>
        ) : null}
        {paymentOption.fundBalanceForfeitDate ? (
          <SharedLibraryTypography variant="body02">
            {paymentOption.fundBalanceForfeitDate}
          </SharedLibraryTypography>
        ) : null}
      </StyledLabel>
    );
  };

  return (
    <StyledSubPageCheckWireTransfer>
      {title && (
        <StyledTitleContainer>
          <Typography
            variant={typography.heading03Large}
            color={solidColors.midnight}
          >
            {title}
          </Typography>
          {subtitle && (
            <Typography
              variant={typography.body1}
              color={solidColors.darkGray}
            >
              {subtitle}
            </Typography>
          )}
        </StyledTitleContainer>
      )}

      {creditCardFeeCaption &&
        creditCardFeeAmount &&
        selectedCreditCards.length > 0 && (
          <StyledLine separator={true}>
            <Typography
              variant={typography.body2}
              color={solidColors.lava}
            >
              {creditCardFeeCaption}
            </Typography>
            <Typography
              variant={typography.body2}
              color={solidColors.lava}
            >
              {creditCardFeeAmount}
            </Typography>
          </StyledLine>
        )}

      {totalCostCaption && localTotalCostAmount && (
        <StyledLine separator={true}>
          <Typography
            variant={typography.body1}
            color={solidColors.midnight}
          >
            {totalCostCaption}
          </Typography>
          <Typography
            variant={typography.heading03Large}
            color={solidColors.midnight}
          >
            {localTotalCostAmount}
          </Typography>
        </StyledLine>
      )}

      {paymentOptionList &&
        paymentOptionList.map(
          (paymentOption: IPayment, index: number) => (
            <StyledLine key={index}>
              {selectionType === 'radioButton' ? (
                <CustomRadio
                  label={getLabel(paymentOption)}
                  checked={
                    paymentOption.isPreselected || false
                  }
                  onChange={() =>
                    handleSelectedPaymentOptionsChange(
                      paymentOption,
                      true,
                    )
                  }
                />
              ) : (
                <>
                  <Checkbox
                    onChange={(checked) =>
                      handleSelectedPaymentOptionsChange(
                        paymentOption,
                        checked,
                      )
                    }
                    checked={
                      paymentOption.isPreselected || false
                    }
                    label={getLabel(paymentOption)}
                    disabled={isDisabled(paymentOption)}
                  />
                  {paymentOption.isPreselected && (
                    <StyledInput>
                      <InputBase
                        title=""
                        payloadKey={''}
                        type={'currency'}
                        defaultValue={getFormattedInputValue(
                          paymentOption.preselectedSplitValue?.toString() ||
                            '0',
                        )}
                        onChange={(e) =>
                          handleInput(
                            e.target.value,
                            paymentOption,
                          )
                        }
                        disabled={
                          (paymentOption.paymentType ===
                            'creditCard' &&
                          selectedSpecial.length > 0
                            ? true
                            : false) ||
                          paymentOption.paymentType ===
                            'atoFund'
                        }
                        onBlur={(e) =>
                          handleSelectedPaymentOptionsChange(
                            paymentOption,
                            true,
                          )
                        }
                        value={getFormattedInputValue(
                          paymentOption.preselectedSplitValue?.toString() ||
                            '0',
                        )}
                      />
                    </StyledInput>
                  )}
                </>
              )}
            </StyledLine>
          ),
        )}

      {addCreditCardButton && (
        <StyledButtonContainer $first>
          <StyledAddEditButton
            title={addCreditCardButton?.title}
            styleType="icon_leading"
            action={{
              actionMethod: () => {
                use_native_manage_payment_methods
                  ? navigate(
                      '/checkout/add-payment-method?type=credit-cards',
                    )
                  : dispatch(
                      setPaymentLocation(
                        'addCreditCardPopUp',
                      ),
                    );
              },
              actionAnalytics: {
                trackingTitle:
                  'Select_AddNewCreditCardviaSelectPayment_Button',
                properties: [
                  {
                    key: 'Page Name',
                    value: 'Select Payment',
                  },
                ] as IKeyValue[],
              },
            }}
            image={addCreditCardButton?.image}
          />
        </StyledButtonContainer>
      )}
      {checkOrWireTransferButton && (
        <StyledButtonContainer
          $first={!addCreditCardButton}
        >
          <StyledAddEditButton
            title={checkOrWireTransferButton?.title}
            styleType={'icon_leading'}
            action={{
              actionMethod: () => {
                dispatch(
                  setPaymentLocation('checkWirePopUp'),
                );
              },
              actionAnalytics: {
                trackingTitle:
                  'Select_CheckOrWireviaSelectPayment_Button',
                properties: [
                  {
                    key: 'Page Name',
                    value: 'Select Payment',
                  },
                ] as IKeyValue[],
              },
            }}
            image={checkOrWireTransferButton?.image}
          />
        </StyledButtonContainer>
      )}
    </StyledSubPageCheckWireTransfer>
  );
};

export default BookingFlowSelectPayment;
