import React, { useState, useEffect } from 'react';
import { CustomRadio } from '@molecules';
import { IOptionPicker } from './OptionPicker.interfaces';
import { OptionPickerContainer } from './OptionPicker.styles';

const OptionPicker = ({
  options,
  clear,
  onClick,
}: IOptionPicker) => {
  const [answerArray, setAnswerArray] = useState<string[]>([
    '',
  ]);

  const handleOptionClick = (option: string) => {
    setAnswerArray([option]);
    onClick(option);
  };

  useEffect(() => {
    clear && setAnswerArray(['']);
  }, [clear]);

  return (
    <OptionPickerContainer>
      {options.map((option) => (
        <CustomRadio
          key={option.title}
          checked={option.title === answerArray[0]}
          onChange={() =>
            handleOptionClick(option.title as string)
          }
          label={option.title}
          labelPlacement="bottom"
        />
      ))}
    </OptionPickerContainer>
  );
};

export default OptionPicker;
