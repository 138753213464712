import {
  clearLocalStorage,
  clearSessionStorage,
  getFromLocalStorage,
  getFromSessionStorage,
  removeFromLocalStorage,
  saveDataInLocalStorage,
  saveDataInSessionStorage,
} from '@components/utils/storage';

import { DateTime } from 'luxon';
import axios, { AxiosResponse } from 'axios';
import {
  coreClient,
  coreEndpoints,
  endpoints,
} from '../apiService';
import { fetchProcessEnvVariable } from '@services/envService/envService';
import jwt from 'jsonwebtoken';
import qs from 'qs';
import { useQuery } from '@tanstack/react-query';
import { coreApiClient } from '@services/apiService/APIService';

const getApiClient = (url: string | undefined) => {
  const apiClient = axios.create({
    baseURL: `${url}`,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'WU-UUID': 'WEB_APP',
    },
  });
  return apiClient;
};

const pegasusApiClient = () =>
  getApiClient(fetchProcessEnvVariable('PEGASUS_URL'));
const pegasusClient = pegasusApiClient();
interface AuthData {
  message?: string;
  data: {
    access_token: string;
    token_type: string;
    refresh_token: string;
    expires_in: number;
    scope: string;
  };
}

export const authenticate = async (
  email: string,
  password: string,
) => {
  const client_id = fetchProcessEnvVariable(
    'CLIENT_ID',
    true,
  );
  const secret = fetchProcessEnvVariable(
    'CLIENT_SECRET',
    true,
  );
  try {
    const authData: AuthData = (
      await pegasusClient.post<AuthData>(
        endpoints.auth,
        qs.stringify({
          username: email,
          password: password,
          grant_type: 'password',
          scope: 'read write',
          client_id: client_id,
          client_secret: secret,
          profile: 'client',
        }),
      )
    ).data;

    console.log(
      '%c authData',
      'background: #222; color: #bada55',
      authData,
    );
    saveDataInLocalStorage(
      'access_token',
      authData.data.access_token,
    );
    saveDataInLocalStorage(
      'refresh_token',
      authData.data.refresh_token,
    );
    const currDate = new Date();
    const expires = new Date(
      currDate.getTime() + 60000 * 170,
    ); // 170 minutes
    saveDataInLocalStorage(
      'refresh_token_expires',
      expires.toString(),
    );
    saveDataInLocalStorage('attemptedRefresh', 'false');
    // Add Transmit here for login track
  } catch (err: any) {
    // this catch is only for logging purposes, the
    // error is being handled in handleOnLogIn in LoginForm.
    // pass error back to login handler
    console.log(err);
    throw err;
  }
};

export const checkIsTokenValid = async () => {
  const token = getFromLocalStorage('access_token') || '';
  const tokenDecoded = jwt.decode(token, {
    complete: true,
  }) as jwt.JwtPayload;
  if (tokenDecoded?.payload?.exp) {
    const expiryDateOfToken = tokenDecoded.payload.exp;
    // converting to seconds instead of milliseconds
    const now = DateTime.now().valueOf() / 1000;
    const isExpired = expiryDateOfToken < now;
    // call the check endpoint to see if the token is still valid
    if (!isExpired) return true;
    else {
      await refresh(true);
      return true;
    }
  } else {
    await refresh(true);
  }
};

export const refresh = async (forceRefresh: boolean) => {
  const refreshStatus = getFromLocalStorage(
    'attemptedRefresh',
  );
  if (
    (forceRefresh && refreshStatus === 'pending') ||
    refreshStatus === 'true'
  ) {
    return;
  }
  saveDataInLocalStorage('attemptedRefresh', 'pending');
  try {
    const authData = await pegasusClient.post(
      endpoints.auth,
      qs.stringify({
        grant_type: 'refresh_token',
        scope: 'read write',
        client_id: fetchProcessEnvVariable(
          'CLIENT_ID',
          true,
        ),
        client_secret: fetchProcessEnvVariable(
          'CLIENT_SECRET',
          true,
        ),
        profile: 'client',
        refresh_token: getFromLocalStorage('refresh_token'),
      }),
    );
    saveDataInLocalStorage('attemptedRefresh', 'true');
    saveDataInLocalStorage(
      'access_token',
      authData.data.data.access_token,
    );
    saveDataInLocalStorage(
      'refresh_token',
      authData.data.data.refresh_token,
    );
    const currDate = new Date();
    const expires = new Date(
      currDate.getTime() + 60000 * 182,
    ); // 3hrs and 2min
    saveDataInLocalStorage(
      'refresh_token_expires',
      expires.toString(),
    );
  } catch (err: any) {
    console.log(err);
    window.location.href = `${window.location.origin}/signout`;
    return;
  }
};

export const removeAuthentication = () => {
  const userRollout =
    localStorage.getItem('userRollout') === null
      ? 'enabled'
      : localStorage.getItem('userRollout');
  clearLocalStorage();
  clearSessionStorage();
  // this code allows rollout testing and can be removed after May 1st
  // same as variable above
  localStorage.setItem(
    'userRollout',
    userRollout as string,
  );
};

export const authenticateWebview = () => {
  const paramsString = window.location.search;
  const searchParams = new URLSearchParams(paramsString);
  const token = searchParams.get('t');
  const isWebview = searchParams.get('wupWebview');
  if (token && isWebview) {
    saveDataInLocalStorage('access_token', token);
    saveDataInLocalStorage('refresh_token', 'webview');
  }
};

export const isAuthenticated = () => {
  authenticateWebview();
  return !!(
    getFromLocalStorage('access_token') &&
    getFromLocalStorage('refresh_token')
  );
};

export const registerAmexAccount = async () => {
  const data = getFromLocalStorage('_data');
  const token = getFromLocalStorage('access_token') || '';
  const tokenDecoded = jwt.decode(token, {
    complete: true,
  }) as jwt.JwtPayload;

  try {
    const response = await axios.post(
      'https://stagingservices.wheelsup.com/public/amex/member/register',
      {
        memberId: tokenDecoded.payload.memberId,
        cardToken: data.cardToken,
        amexBenefit: data.amexBenefit,
      },
    );

    return response;
  } catch (err) {
    return err;
  }
};

export const useSalesforceProductAuthentication = ({
  token,
  enabled = true,
}: {
  token: string;
  enabled: boolean;
}) => {
  enabled && clearLocalStorage();
  enabled && clearSessionStorage();
  const { data, isError, isLoading } = useQuery({
    queryKey: ['salesforce-authenticate'],
    queryFn: async () => {
      try {
        const res = await coreClient.post(
          `${coreEndpoints.generalProductAuthentication}?linkAccessCode=${token}`,
        );
        return res ?? {}; // Return an empty object if `res` is undefined
      } catch (error) {
        console.error(
          'Error during authentication:',
          error,
        );
        return {}; // Return an empty object if an error occurs
      }
    },
    enabled,
    cacheTime: 1000 * 60 * 10, // 10 minutes
    staleTime: Infinity,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  });
  let accessToken = '';
  if (data) {
    accessToken = (data as any)?.data?.access_token;
    accessToken &&
      saveDataInLocalStorage('access_token', accessToken);
  }
  return { accessToken, isError, isLoading };
};
