import { solidColors } from '@constants/styles/colors.constants';
import { theme } from '@constants/styles/theme.constants';
import styled from 'styled-components';

export const BarChartContainer = styled.div`
  overflow-x: auto;
  height: 100%;
  ::-webkit-scrollbar {
    height: 8px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${solidColors.upBlue.color};
    border-radius: 100px;
  }
  ::-webkit-scrollbar-track {
    background-color: ${solidColors.lightGray.color};
    border-radius: 100px;
    height: 8px;
  }
`;

export const BarsSection = styled.div<{
  maxHeightOfBarAndTopLabel: number;
}>`
  display: flex;
  justify-content: space-between;
  gap: 10px;
  align-items: end;
  height: ${({ maxHeightOfBarAndTopLabel }) =>
    maxHeightOfBarAndTopLabel}px;
`;

export const BarContainer = styled.div<{
  ratioOfMaxHeight: number;
}>`
  height: calc(
    (
        ${({ ratioOfMaxHeight }) => ratioOfMaxHeight} *
          calc(100% - 32px)
      ) + 32px
  );
  min-width: 18%;
  width: 100%;
  display: flex;
  flex-direction: column;

  @media (max-width: ${theme.breakpoints.values.sm}px) {
    min-width: 20%;
  }
`;

export const TopLabelContainer = styled.div`
  margin-bottom: 8px;
`;

export const BottomLabelWrapper = styled.div<{
  addMarginForScroll?: boolean | null;
}>`
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 10px;
  margin-top: 8px;
  margin-bottom: ${({ addMarginForScroll }) =>
    addMarginForScroll ? '16px' : ''};
`;

export const BottomLabelContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 18%;
  width: 100%;

  @media (max-width: ${theme.breakpoints.values.sm}px) {
    min-width: 20%;
  }
`;

export const BottomLabelInTwoLines = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  white-space: pre;
`;

export const Bar = styled.div<{
  isSelected: boolean | undefined;
}>`
  background: ${({ isSelected }) =>
    isSelected
      ? 'linear-gradient(180deg, #9AA3E6 0%, #173FC2 100%);'
      : 'linear-gradient(180deg, #E7E9F9 0%, #9AA3E6 100%);'}
  border-radius: 8px;
  flex-grow: 1;
`;
