import {
  FormControl,
  InputLabel,
  Select,
} from '@components/library';
import { solidColors } from '@constants/styles/colors.constants';
import { theme } from '@constants/styles/theme.constants';
import { Divider } from '@material-ui/core';
import { Typography } from '@wheelsup/wu-react-components';
import styled from 'styled-components';

export const ChipContainer = styled.div`
  display: flex;
  padding-bottom: 24px;
`;

export const SelectContainer = styled.div`
  display: flex;
`;

export const QuickSelectContainer = styled.div`
  display: flex;
  gap: 16px;
  padding-bottom: 24px;
`;

export const DisplayContainer = styled.div`
  display: flex;
  @media screen and (max-width: ${theme.breakpoints.values
      .md}px) {
    flex-direction: column;
  }
  justify-content: space-between;
`;

export const StyledDivider = styled(Divider)`
  margin-bottom: 24px;
  margin-top: 24px;
`;

export const TimeSelectContainer = styled(FormControl)`
  margin-top: 10px;
  margin-bottom: 24px;
  height: 64px;
  padding: 0px 10px;
  padding-top: 6px;
  cursor: pointer;
  width: 100%;
  border-radius: 4px;
  border: 1px solid ${solidColors.mediumGray.color};
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.03);
`;

export const TimePickerLabel = styled(Typography)`
  top: 10px;
  left: 10px;
`;

export const TimeSelect = styled(Select)`
  font-family: 'AktivRegular';
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 133.333% */
  color: var(--text-text-primary, #041c46);
`;

export const ChooseTimeCopy = styled(Typography)`
  padding-bottom: 10px;
`;

export const QuickSelectHeader = styled.div`
  padding-bottom: 16px;
`;

export const ToggleWrapper = styled.div`
  cursor: pointer;
`;

export const TimePickerWrapper = styled.div`
  position: relative;
  top: 2px;
`;
