import CustomSubpage from '../../CustomSubpage/CustomSubpage';
import {
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useFlightSearch } from '../../SubPageFlightSearchV2/FlightSearchContext/FlightSearchHooks';
import { FlightSearchContext } from '../../SubPageFlightSearchV2/FlightSearchContext/FlightSearchContext';
import { useOnClickOutside } from '@hooks';
import TimePickerDisplay from './TimePickerDisplay';
import { getTimeSubpage } from './TimePicker.services';
import { useFeatureFlag } from '@services/featureFlagService/featureFlagService.services';
import { FeatureFlags } from '@services/featureFlagService/constants';
import { TimePickerWrapper } from './TimePickerDisplay.styles';

const TimePickerV2 = ({
  legNumber,
}: {
  legNumber: number;
}) => {
  const {
    legs,
    updateLeg,
    isRoundTrip,
    contextDispatch,
  } = useFlightSearch(useContext(FlightSearchContext));
  const useTod = useFeatureFlag(FeatureFlags.USE_TOD);

  const leg = legs[legNumber];
  const [
    departureAirportHoursCopy,
    setDepartureAirportHoursCopy,
  ] = useState('');
  const [
    arrivalAirportHoursCopy,
    setArrivalAirportHoursCopy,
  ] = useState('');

  const handleContinue = (openNewPicker: boolean) => {
    if (isRoundTrip) {
      // leg 0 is what we use to kep track of the shouldBeOpen state variables
      contextDispatch({
        payload: {
          legs: [
            {
              ...legs[0],
              timeOpen: false,
              ...(useTod
                ? undefined
                : {
                    passengersOpen: openNewPicker,
                  }),
            },
            {
              ...legs[1],
            },
          ],
        },
        type: 'replaceLegsRoundTrip',
      });
    } else {
      updateLeg(
        {
          ...leg,
          timeOpen: false,
          ...(useTod
            ? undefined
            : {
                passengersOpen: openNewPicker,
              }),
        },
        legNumber,
      );
    }
  };

  useEffect(() => {
    if (leg.departureAirport && leg.arrivalAirport) {
      getTimeSubpage({
        leg,
        setArrivalAirportHoursCopy,
        setDepartureAirportHoursCopy,
      });
    }
  }, [
    leg.departureAirport?.code,
    leg.arrivalAirport?.code,
  ]);

  const selectRef = useRef(null);

  const sharedProps = {
    legNumber,
    departureAirportHoursCopy,
    arrivalAirportHoursCopy,
    selectRef,
  };

  useOnClickOutside(selectRef, () => handleContinue(false));

  return (
    <TimePickerWrapper>
      <div ref={selectRef}>
        <CustomSubpage
          button={{
            title: 'Next',
          }}
          alignRight
          action={() => handleContinue(true)}
          actionTopGap={16}
        >
          {isRoundTrip ? (
            <>
              <TimePickerDisplay
                {...sharedProps}
                legNumber={0}
                text={'Depart'}
              />
              <TimePickerDisplay
                {...sharedProps}
                legNumber={1}
                text={'Return'}
              />
            </>
          ) : (
            <TimePickerDisplay
              {...sharedProps}
              legNumber={legNumber}
              text={'Depart'}
            />
          )}
        </CustomSubpage>
      </div>
    </TimePickerWrapper>
  );
};

export default TimePickerV2;
