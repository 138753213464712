import {
  Dropdown,
  Elevation,
  Toggle,
  Typography,
  WidthOptions,
} from '@wheelsup/wu-react-components';
import { useViewport } from '@hooks';
import { DateTime } from 'luxon';
import { useLayoutEffect, useMemo, useState } from 'react';
import {
  ChartContainer,
  DropdownContainer,
  DropdownHeader,
  DropdownSection,
  DropdownWithRightContent,
  TitleTabContainer,
  ToggleBottomNote,
  ToggleContainer,
  ToggleSection,
  ToggleWrapper,
} from './CardPostFlightSearchTimePicker.styles';
import BarChart from '@molecules/BarChart/BarChart';
import { theme } from '@constants/styles/theme.constants';
import { getFormattedCurrency } from '@components/utils/formatCurrency';
import TimeDropdownRightContent from './TimeDropdownRightContent';
import {
  fireMixpanelEventOnTimeChange,
  formatTimeStringToAMPM,
  generateTimeOptions,
  timePickerDropdownChangeApiPayload,
} from './CardPostFlightSearchTimePicker.utils';
import TimeDropdownBottomContent from './TimeDropdownBottomContent';
import {
  IFlightLegTimePicker,
  IFlightLegTimePickerState,
  ITimeBlock,
  ITimeBlockState,
} from './CardPostFlightSearchTimePicker.interfaces';
import { useFeatureFlag } from '@services/featureFlagService/featureFlagService.services';
import { FeatureFlags } from '@services/featureFlagService/constants';
import { asyncThunkFactory } from '@services/sliceFactoryService/requestSlice';
import { pageSliceOptions } from '@services/sliceFactoryService/sliceFactoryService.types';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '@app/hooks';
import {
  getFromSessionStorage,
  saveDataInSessionStorage,
} from '@components/utils/storage';
import { trackAnalytics } from '@services/mixpanelService/mixpanel.service';
import TimePickerAlert from './TimePickerAlert';

export const CardPostFlightSearchTimePicker = ({
  flightLegs,
  reservationType,
  todErrorMessage,
}: {
  flightLegs: IFlightLegTimePicker[];
  reservationType: string;
  todErrorMessage?: string;
}) => {
  const dispatch = useDispatch();
  const useTod = useFeatureFlag(FeatureFlags.USE_TOD);
  const [selectedLegIndex, setSelectedLegIndex] = useState(
    0,
  );
  const [flightLegsState, setFlightLegsState] = useState<
    IFlightLegTimePickerState[]
  >([]);

  const {
    selectedPassengersLeg,
    selectedPetsLegs,
    luggages,
  } = useAppSelector((state) => state.searchTripDetails);

  const { width } = useViewport();
  const isMobile = width <= theme.breakpoints.values.sm;

  const flightType = getFromSessionStorage('searchCriteria')
    .flightType;

  useLayoutEffect(() => {
    const lastSelectedLegIndex = getFromSessionStorage(
      'timePickerSelectedLegIndex',
    );
    if (lastSelectedLegIndex) {
      setSelectedLegIndex(lastSelectedLegIndex);
    }
    flightLegs.forEach((e, index) => {
      saveDataInSessionStorage(
        `departureTimeTodLeg${index}`,
        e.selectedTime,
      );
      saveDataInSessionStorage(
        `requestLegIdLeg${index}`,
        e.requestLegId,
      );
    });
    setFlightLegsState([
      ...flightLegs.map((obj: IFlightLegTimePicker) => {
        const formattedTimeObj = formatTimeStringToAMPM(
          obj.selectedTime,
        );
        const newObj = {
          ...obj,
          selectedTime: {
            label: formattedTimeObj.ampmTime,
            value: obj.selectedTime,
          },
          timeBlocks: obj.timeBlocks.map(
            (e: ITimeBlock) => ({
              ...e,
              startTimeDtObj: DateTime.fromFormat(
                e.startTime,
                'HH:mm:ss',
              ),
              endTimeDtObj: DateTime.fromFormat(
                e.endTime,
                'HH:mm:ss',
              ),
            }),
          ),
        };
        return {
          ...newObj,
          //below field not needed for now, keeping it for graphql conversion of trip details page
          selectedTimeBlockIndex: newObj.timeBlocks.findIndex(
            (e: ITimeBlockState) => e.isSelected,
          ),
        };
      }),
    ]);
  }, [flightLegs]);

  const selectedLeg = flightLegsState?.[selectedLegIndex];

  const timeOptions = useMemo(() => {
    if (selectedLeg?.timeBlocks) {
      return generateTimeOptions(selectedLeg.timeBlocks);
    }
    return [{ label: '', value: '' }];
  }, [selectedLeg?.timeBlocks]);

  if (!useTod || flightType === 'Multi city') return null;

  return (
    <Elevation
      wrapperWidth={WidthOptions.PCT100}
      variant="raisedLevel2"
    >
      <TitleTabContainer
        applyAlertMargin={!!todErrorMessage}
      >
        <Typography variant="heading03Responsive">
          Departure time
        </Typography>
        {selectedLeg &&
          selectedLeg.timeBlocks.length > 1 && (
            <Typography variant="body02">
              Time selection affects total cost.
            </Typography>
          )}
      </TitleTabContainer>
      {todErrorMessage && (
        <TimePickerAlert
          title="Select a different time"
          copy={todErrorMessage}
        />
      )}
      {flightLegsState.length > 1 && (
        <ToggleSection>
          <ToggleContainer>
            {flightLegsState.map(
              (
                flightLeg: IFlightLegTimePickerState,
                i: number,
              ) => (
                <ToggleWrapper
                  index={i}
                  key={`${flightLeg.legDetail}-${i}`}
                >
                  <Toggle
                    variant="tab"
                    label={flightLeg.selectedTime.label}
                    subLabel={flightLeg.legDetail}
                    onChange={() => {
                      setSelectedLegIndex(i);
                      saveDataInSessionStorage(
                        'timePickerSelectedLegIndex',
                        i,
                      );
                      trackAnalytics({
                        trackingTitle:
                          i === 0
                            ? 'Select_FirstLegTimeSectionSubNav'
                            : 'Select_SecondLegTimeSectionSubNav',
                        properties: [],
                      });
                    }}
                    pressed={i === selectedLegIndex}
                  />
                </ToggleWrapper>
              ),
            )}
          </ToggleContainer>
          <ToggleBottomNote>
            <Typography variant="body02">
              Est. arrival time{' '}
              {
                formatTimeStringToAMPM(
                  selectedLeg.estimatedArrivalTime,
                ).ampmTime
              }
            </Typography>
          </ToggleBottomNote>
        </ToggleSection>
      )}
      <>
        {selectedLeg?.timeBlocks.length > 1 &&
          reservationType !== 'request-quote' && (
            <ChartContainer>
              <BarChart
                maxHeightOfBarAndTopLabel={128}
                bars={selectedLeg.timeBlocks.map(
                  (timeBlock: ITimeBlockState) => ({
                    topLabel: timeBlock.price
                      ? getFormattedCurrency(
                          {
                            amount: timeBlock.price as number,
                            unit: 'USD',
                            symbol: '$',
                          },
                          0,
                        )
                      : 'Not available',
                    bottomLabel: {
                      labelLine1: `${DateTime.fromFormat(
                        timeBlock.startTime,
                        'HH:mm:ss',
                      ).toFormat('h:mm a')} -`,
                      labelLine2: ` ${DateTime.fromFormat(
                        timeBlock.endTime,
                        'HH:mm:ss',
                      ).toFormat('h:mm a')}`,
                      label: timeBlock.timeSlot,
                    },
                    isSelected: timeBlock.isSelected,
                    value: timeBlock.price as number,
                  }),
                )}
                bottomLabelIn2LinesForLimitedSpace={true}
              />
            </ChartContainer>
          )}
        <DropdownSection>
          <DropdownHeader
            variant={isMobile ? 'body02Bold' : 'body01Bold'}
          >
            Select time
          </DropdownHeader>
          <DropdownWithRightContent>
            <DropdownContainer>
              <Dropdown
                name="departTime"
                items={timeOptions}
                value={
                  selectedLeg?.selectedTime || {
                    label: '',
                    value: '',
                  }
                }
                onChange={(selectedTimeObj: {
                  label: string;
                  value: string;
                }) => {
                  saveDataInSessionStorage(
                    `departureTimeTodLeg${selectedLegIndex}`,
                    selectedTimeObj.value,
                  );
                  dispatch(
                    asyncThunkFactory(
                      pageSliceOptions.flySearchTripDetails,
                    )({
                      resPayload: timePickerDropdownChangeApiPayload(
                        selectedPassengersLeg,
                        selectedPetsLegs,
                        luggages,
                        flightLegsState,
                        selectedTimeObj.value,
                        selectedLegIndex,
                      ),
                    }),
                  );
                  fireMixpanelEventOnTimeChange(
                    selectedTimeObj,
                    flightLegsState,
                    selectedLegIndex,
                  );
                }}
              />
            </DropdownContainer>
            {selectedLeg && (
              <TimeDropdownRightContent
                timeBlocksCount={
                  selectedLeg.timeBlocks.length
                }
                reservationType={reservationType}
                legsCount={flightLegsState.length}
                price={selectedLeg.timeBlocks[0].price}
                timeSlot={
                  selectedLeg.timeBlocks[0].timeSlot
                }
              />
            )}
          </DropdownWithRightContent>
          {selectedLeg && (
            <TimeDropdownBottomContent
              legsCount={flightLegsState.length}
              timeBlocksCount={
                selectedLeg.timeBlocks.length
              }
              reservationType={reservationType}
              estimatedArrivalTime={
                selectedLeg.estimatedArrivalTime
              }
            />
          )}
        </DropdownSection>
      </>
    </Elevation>
  );
};
