import { setIsAppLoading } from '@app/AppLoadingSlice';
import { RootState, AppDispatch } from '@app/store';
import {
  getFromLocalStorage,
  saveDataInLocalStorage,
  saveDataInSessionStorage,
} from '@components/utils/storage';
import { EnhancedStore } from '@reduxjs/toolkit';
import { AxiosInstance } from 'axios';
import {
  cacheEndpoints,
  cachePageEndpoints,
} from '../constants';
import { ignoredGlobalLoadingUrls } from './ignoreGlobalLoadingUrls';

{
  /*
    current pages using FE caching
    - Dashboard
    - Fly
    - My Trips
    - Account
    - Members
    - Hub
*/
}

let storeGetState: RootState;
let storeDispatch: AppDispatch;

export const injectStoreRequest = (
  _store: EnhancedStore,
) => {
  storeGetState = _store.getState();
  storeDispatch = _store.dispatch;
};

export const requestInterceptor = (
  instance: AxiosInstance,
) => {
  instance.interceptors.request.use((request) => {
    const path = window.location.pathname;
    const globalLoading = !ignoredGlobalLoadingUrls.has(path);
    globalLoading && storeDispatch(setIsAppLoading(true));
    let isPage = false;
    const apiUrl = request.url;
    if (apiUrl) {
      isPage = apiUrl.includes('pages');
    }

    // This stores a path in local storage to be used to route users to their intended page
    // if signin is required first.
    const token = getFromLocalStorage('access_token');

    const endpoint = request.url?.split('?')[0] as string;
    const cache = getFromLocalStorage(endpoint);
    const etag = cache && cache.etag;
    const isCachable =
      cachePageEndpoints.has(endpoint) ||
      cacheEndpoints.has(endpoint);

    if (isCachable && etag) {
      request.headers['If-None-Match'] = etag;
    }
    if (token) {
      request.headers['Authorization'] = `Bearer ${token}`;
      request.headers['WUToken'] = token;
    }

    return request;
  });
};
