import { Typography } from '@wheelsup/wu-react-components';
import styled from 'styled-components';

export const TitleTabContainer = styled.div<{
  applyAlertMargin: boolean;
}>`
  margin-bottom: ${({ applyAlertMargin }) =>
    applyAlertMargin ? '16px' : '32px'};
`;

export const ToggleSection = styled.div`
  margin-bottom: 32px;
`;

export const ToggleContainer = styled.div`
  display: flex;
`;

export const ToggleWrapper = styled.div<{
  index: number;
}>`
  width: 50%;
  margin-right: ${({ index }) =>
    index === 0 ? '16px' : ''};
  cursor: pointer;
`;

export const ToggleBottomNote = styled.div`
  margin-top: 8px;
`;

export const ChartContainer = styled.div`
  width: 100%;
  margin-bottom: 32px;
`;

export const DropdownHeader = styled(Typography)`
  margin-bottom: 8px;
`;

export const DropdownSection = styled.div`
  margin-bottom: 2px;
`;

export const DropdownWithRightContent = styled.div`
  display: flex;
`;

export const DropdownContainer = styled.div`
  width: 158px;
  margin-right: 24px;
`;

export const DropdownBottomNote = styled.div`
  margin-top: 8px;
`;

export const DropdownRightPriceSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  gap: 4px;
`;

export const TimePickerAlertWrapper = styled.div`
  padding: 16px;
  display: flex;
  background: rgba(255, 96, 55, 0.05);
  border-radius: 8px;
  margin-bottom: 16px;
`;

export const TimePickerAlertIconWrapper = styled.div`
  margin-right: 8px;
`;
