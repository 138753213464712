import { InputWrapper } from '@components/molecules/FormFlightLeg/SearchFlight.styles';
import {
  Icon,
  TextInput,
} from '@wheelsup/wu-react-components';
import { useContext, useEffect, useState } from 'react';
import { FlightSearchContext } from '../SubPageFlightSearchV2/FlightSearchContext/FlightSearchContext';
import { DateTime } from 'luxon';
import { useFlightSearch } from '../SubPageFlightSearchV2/FlightSearchContext/FlightSearchHooks';
import TimePickerV2 from './TimePickerV2/TimePickerV2';
import styled from 'styled-components';
import { theme } from '@constants/styles/theme.constants';

const TimePickerWrapper = styled.div`
  @media (min-width: ${theme.breakpoints.values.sm}px) {
    position: absolute;
    min-width: calc(calc(100% - 20px) * 0.666 + 10px);
  }
`;

const TimeSection = ({
  legNumber,
}: {
  legNumber: number;
}) => {
  const { legs, updateLeg, isRoundTrip } = useFlightSearch(
    useContext(FlightSearchContext),
  );
  const leg = legs[legNumber];

  const retrieveTimeString = () => {
    if (isRoundTrip) {
      const departTrip = legs[0];
      const returnTrip = legs[1];
      return `${DateTime.fromJSDate(
        departTrip.departTime,
      ).toFormat('h:mm a')} / ${DateTime.fromJSDate(
        returnTrip.departTime,
      ).toFormat('h:mm a')}`;
    }
    return DateTime.fromJSDate(leg.departTime).toFormat(
      'hh:mm a',
    );
  };

  return (
    <InputWrapper>
      <TextInput
        onClick={() =>
          updateLeg({ timeOpen: true }, legNumber)
        }
        name={'TimeInput'}
        label={'Time'}
        labelSize={'md'}
        variant={'md'}
        value={retrieveTimeString()}
        isReadOnly={true}
        leftIcon={
          <Icon color="secondary" name="clock" size="s" />
        }
        errorMessage={
          leg.timeError && 'ERROR MESSAGE COMING SOON'
        }
        className="selectedBorder"
      />
      {leg.timeOpen && (
        <TimePickerWrapper>
          <TimePickerV2 legNumber={legNumber} />
        </TimePickerWrapper>
      )}
    </InputWrapper>
  );
};
export default TimeSection;
