import { Icons } from '@components/library';
import {
  makeStyles,
  Theme,
} from '@material-ui/core/styles';
import styled from 'styled-components';
import { solidColors } from '@constants/styles/colors.constants';
import { Button, ImageImgix, TextField } from '@atoms';
import { IconButton } from '@library';
import { Typography } from '@atoms';
import { IStyledIcon } from '@molecules/AirportSearchListItem/AirportSearchListItem.interfaces';

export const useStyles = makeStyles((theme: Theme) => ({
  inputRoot: {
    textOverflow: 'ellipsis',
    padding: '5px 0px 16px 16px',
    width: '370px !important',
    '@media (max-width: 600px)': {
      width: '302px !important',
    },
    color: ({ transparent }: { transparent?: boolean }) =>
      transparent
        ? solidColors.midnight.color
        : theme.palette.text.primary,
    fontFamily: theme.typography.fontFamily,
    fontSize: '18px',
    letterSpacing: '-0.94px',
    lineHeight: '24px',
    '&::placeholder': {
      color: ({
        transparent,
        disable,
      }: {
        transparent?: boolean;
        disable?: boolean;
      }) =>
        transparent
          ? disable
            ? solidColors.mediumGray.color
            : solidColors.midnight.color
          : theme.palette.text.primary,
      opacity: 1,
    },
  },
  popupIndicator: {
    '& span': {
      '& svg': {
        fill: 'white',
      },
    },
  },
  paper: {
    height: '534px',
    width: '528px',
    color: solidColors.upWhite.color,
  },
  textField: {
    paddingRight: '0 !important',
  },
  noOptions: {
    color: solidColors.midnight.color,
    fontSize: '28px',
    fontFamily: theme.typography.fontFamily,
    letterSpacing: '-0.48px',
    lineHeight: '34px',
    margin: '40px 38px 0 38px',
    textAlign: 'center',
  },
}));

export const StyledPlaceholderContainer = styled.div`
  align-items: center;
  font-size: 1.125rem;
  letter-spacing: -0.27px;
  line-height: 19px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
  padding: 16px 16px 0px 16px;

  .inline-svg {
    width: 1rem;
    margin-right: 0.5rem;
  }

  .error-message {
    color: ${({ theme }) => theme.palette.error.main};
  }
`;

export const LabelContainer = styled.div`
  flex-grow: 1;
`;

export const StyledTextField = styled(TextField)<{
  $textFieldWidth?: string;
}>`
  width: fit-content;
  min-width: ${({ $textFieldWidth }) =>
    $textFieldWidth ?? '320px'};
  max-width: ${({ $textFieldWidth }) =>
    $textFieldWidth ?? '370px'};
`;

export const IconContainer = styled.div<{
  deleteIcon?: boolean;
}>`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 100;
  width: 50px;
  max-height: 80px;
  height: -webkit-fill-available;
  justify-content: center;
  display: flex;
  align-content: center;
  align-items: center;
`;

export const DeleteIconButton = styled(
  IconButton,
)<IStyledIcon>`
  border-radius: 0;
  background-repeat: no-repeat;
  background-size: contain;
  padding: 0;
`;

export const DeleteIcon = styled(Icons.HighlightOff)`
  color: ${solidColors.upBlue.color};
  margin: 7px;
`;

export const AutocompleteContainer = styled.div<{
  backgroundColor?: string;
}>`
  width: fit-content;
  cursor: pointer;
  width: 100%;
  position: relative;
  border: 1px solid ${solidColors.mediumGray.color};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: baseline;
  background-color: ${(props) => props.backgroundColor};
`;

export const TypographyStyled = styled(Typography)`
  font-size: 18px;
`;

export const Border = styled.div<{
  color?: string;
}>`
  border-bottom: solid 1px;
  border-color: ${({ color }) =>
    color ?? solidColors.midnight.color};
  height: 1px;
  margin-top: 2px;

  ${(props) => props.theme.breakpoints.down('sm')} {
    width: 100%;
  }
`;

export const HomeIcon = styled(ImageImgix)`
  filter: invert(24%) sepia(12%) saturate(2392%)
    hue-rotate(179deg) brightness(92%) contrast(89%);
  margin-right: 7px;
`;

export const CTAButton = styled(Button)`
  margin-top: 2px;
`;

export const BtnContainer = styled.div`
  display: flex;
`;

export const NearbyAirContainer = styled.div`
  width: 530px;
  height: 400px;
  position: absolute;
  background-color: white;
  z-index: 3;
  margin-top: 2px;
  display: flex;
  flex-direction: column;
  padding: 12px 25px;
  justify-content: space-between;
  border-radius: 5px;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
`;

export const NearbyAirItemContainer = styled.div`
  height: 100%;
  flex: 1;
  overflow-y: scroll;
`;

export const NearbyButtonWrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;
`;
