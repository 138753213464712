import { ISelectItem } from './CardHeadlineSearchSelectCTADismissSelect.interfaces';
import { solidColors } from '@constants/styles/colors.constants';
import { typography } from '@constants/styles/typography.constants';
import { Typography } from '@atoms';
import { ListItemText, ListItemIcon } from '@library';
import { CustomRadio } from '@molecules';
import { ListItemStyled } from './CardHeadlineSearchSelectCTADismissSelect.styles';

export const SingleSelect = ({
  item,
  checked,
  onClick,
}: ISelectItem) => (
  <ListItemStyled
    onClick={onClick}
    dense
    button
    value={item}
  >
    <ListItemIcon>
      <CustomRadio
        marginLeft="0rem"
        marginRight="0rem"
        checked={checked}
        onChange={() => null}
      />
    </ListItemIcon>
    <ListItemText>
      <Typography
        variant={typography.heading05Large}
        color={solidColors.darkGray}
        truncate={1}
      >
        {item}
      </Typography>
    </ListItemText>
  </ListItemStyled>
);
