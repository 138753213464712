import { fetchProcessEnvVariable } from '@services/envService/envService';
import axios from 'axios';

const getApiClient = (url: string | undefined) => {
  const userAgent = `WheelsUp/${process.env.REACT_APP_VERSION} ${window.navigator.userAgent}`;
  const apiClient = axios.create({
    baseURL: `${url}`,
    headers: {
      'Content-Type': 'application/json',
      'Wu-User-Agent': userAgent,
      'WU-UUID': 'WEB_APP',
    },
  });

  return apiClient;
};

const getCoreApiClient = (url: string | undefined) => {
  const apiClient = axios.create({
    baseURL: `${url}`,
    headers: {
      'Content-Type': 'application/json',
    },
  });

  return apiClient;
};

export const pegasusApiClient = () =>
  getApiClient(fetchProcessEnvVariable('PEGASUS_URL'));

export const coreApiClient = () =>
  getCoreApiClient(fetchProcessEnvVariable('CORE_URL'));

export const legacyApiClient = () =>
  getApiClient(fetchProcessEnvVariable('CORE_LEGACY_URL'));
