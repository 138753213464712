import { useState, useEffect, useRef } from 'react';
import { FlightLegItemLuggageInfo } from '@molecules';
import { IContainerTripLuggageEditor } from './ContainerTripLuggageEditor.interfaces';
import { Button } from '@atoms';
import {
  Elevation,
  Toggle,
  Typography,
  WidthOptions,
} from '@wheelsup/wu-react-components';
import {
  StyledCard,
  StyledWrapTitle,
  StyledMaxLuggageAlertWrapper,
  StyledCardSearchTripDetails,
  StickyContainer,
} from './ContainerTripLuggageEditor.styles';
import { createPage } from '@services/dynamicRenderingService/dynamicRendering.service';
import { useAppSelector } from '@app/hooks';

import { useDispatch } from 'react-redux';
import {
  ILuggageData,
  setLuggages,
  setSameLuggagesFlag,
} from '@features/Fly/FlySearchTripDetailsSlice';
import CarouselScrollHorizontal from '@components/organisms/CarouselScrollHorizontal/CarouselScrollHorizontal';
import { PaxContainer } from '../ContainerTripPassengerEditor/ContainerTripPassengerEditor.styles';
import { IFlightLegItemLuggageInfoItem } from '../FlightLegItemLuggageInfo/FlightLegItemLuggageInfo.interfaces';
import { useViewport } from '@hooks';
import { useParams } from 'react-router-dom';

const ContainerTripLuggageEditor = ({
  title,
  infoIcon,
  subtitle,
  maxLuggageAlert,
  isWithinChangeWindow,
  listOfLuggageInfoForAllLegs,
}: IContainerTripLuggageEditor) => {
  const dispatch = useDispatch();
  const { reservationId } = useParams();
  const { width } = useViewport();
  const { luggages, sameLuggagesFlag } = useAppSelector(
    (state) => state.searchTripDetails,
  );
  const luggagesStateData = useAppSelector(
    (state) => state.searchTripDetails,
  );
  const [
    isSameLuggageChecked,
    setIsSameLuggageChecked,
  ] = useState(!luggagesStateData.showAsCarousel);
  const [hasMaxLuggage, setHasMaxLuggage] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const [load, setLoad] = useState(false);
  const [luggageData, setLuggageData] = useState<
    IFlightLegItemLuggageInfoItem[]
  >(listOfLuggageInfoForAllLegs);
  const [parentWidth, setParentWidth] = useState(0);
  const [
    fallbackLuggageData,
    setFallbackLuggageData,
  ] = useState<ILuggageData | null>(luggages);

  useEffect(() => {
    const parent = document.getElementById(
      'SubPageTripDetailsWebOnly',
    );
    if (parent) {
      setParentWidth(parent.clientWidth);
    }
  }, [width]);

  useEffect(() => {
    if (listOfLuggageInfoForAllLegs === undefined) {
      return;
    } else if (Object.keys(luggages).length === 0) {
      listOfLuggageInfoForAllLegs.forEach((leg, index) => {
        dispatch(
          setLuggages({
            luggages: leg.listOfLuggageItems,
            index,
          }),
        );
      });
    }
    setLoad(true);
  }, []);

  const handleLegClick = (
    e: React.MouseEvent<HTMLDivElement>,
  ) => {
    e.preventDefault();
    const { id } = e.currentTarget;
    const index = parseInt(id.split('-')[1]);
    setActiveIndex(index);
  };

  const legNames = listOfLuggageInfoForAllLegs?.map(
    (leg, index) => {
      const key = `luggageSlide-${index}`;
      return (
        <div key={key} id={key} onClick={handleLegClick}>
          <Toggle
            label={leg.flightItemTitle || ''}
            pressed={index === activeIndex}
            onChange={() => null}
          />
        </div>
      );
    },
  );

  const handleSwitch = () => {
    dispatch(setSameLuggagesFlag(!sameLuggagesFlag));
    if (!sameLuggagesFlag) {
      setActiveIndex(0);
      setFallbackLuggageData(luggages);
      listOfLuggageInfoForAllLegs.forEach((leg, idx) => {
        if (idx === 0) return;
        dispatch(
          setLuggages({
            luggages: luggages[0].luggageData,
            index: idx,
            flightId: leg.flightId,
          }),
        );
      });
    } else {
      dispatch(
        setLuggages({
          luggages: fallbackLuggageData,
          index: null,
        }),
      );
    }
  };

  const infoIconAction = infoIcon ? infoIcon.action : {};
  const Card = window.location.pathname.includes('fly')
    ? StyledCardSearchTripDetails
    : StyledCard;
  return (
    <>
      {load && (
        <Elevation
          wrapperWidth={WidthOptions.PCT100}
          variant="raisedLevel2"
          padding={0}
        >
          <Card
            data-name="ContainerTripLuggageEditor"
            id="ContainerTripLuggageEditor"
            ref={ref}
            $width={parentWidth}
          >
            <StyledWrapTitle>
              <Typography
                variant="heading03"
                color="primary"
              >
                {title}
              </Typography>
              <Button
                image={infoIcon?.image}
                action={infoIconAction}
                styleType="white"
              />
            </StyledWrapTitle>
            {hasMaxLuggage ? (
              <StyledMaxLuggageAlertWrapper>
                {createPage(maxLuggageAlert)}
              </StyledMaxLuggageAlertWrapper>
            ) : (
              <StickyContainer>
                <Typography
                  variant="body02"
                  color="primary"
                >
                  For a smoother travel experience, please
                  provide accurate luggage details to avoid
                  delays.
                </Typography>
              </StickyContainer>
            )}
            {listOfLuggageInfoForAllLegs?.length > 1 && (
              <div style={{ margin: '8px 0' }}>
                <CarouselScrollHorizontal
                  parentWidth={
                    ref?.current?.clientWidth || 1
                  }
                  inComponent
                >
                  {legNames}
                </CarouselScrollHorizontal>
              </div>
            )}
            {listOfLuggageInfoForAllLegs.map(
              (luggage, idx) => {
                const key = `luggageSlide-${idx}`;
                return (
                  activeIndex === idx && (
                    <PaxContainer key={key}>
                      <FlightLegItemLuggageInfo
                        maximumTotalLuggage={
                          luggage?.maximumTotalLuggage
                        }
                        flightItemTitle={
                          luggage?.flightItemTitle
                        }
                        addLuggageCopy={
                          luggage?.addLuggageCopy
                        }
                        addLuggageLeadingIcon={
                          luggage?.addLuggageLeadingIcon
                        }
                        listOfLuggageItems={
                          luggage?.listOfLuggageItems
                        }
                        sameLuggageForAllFlightsCopy={
                          luggage?.sameLuggageForAllFlightsCopy
                        }
                        luggageCheckedAction={handleSwitch}
                        setHasMaxLuggage={setHasMaxLuggage}
                        quantityOfLegs={
                          listOfLuggageInfoForAllLegs.length
                        }
                        index={activeIndex}
                        flightId={reservationId}
                        isWithinChangeWindow={
                          isWithinChangeWindow
                        }
                      />
                    </PaxContainer>
                  )
                );
              },
            )}
          </Card>
        </Elevation>
      )}
    </>
  );
};

export default ContainerTripLuggageEditor;
