import React, { useReducer } from 'react';
import { standardFlightObj } from '../initialState';
import { endOfNextMonth } from '../../DateSection/dateHelper';
import {
  FlightSearchContextType,
  FlightType,
  IFlightSearchProviderProps,
  PricingParameters,
  TInitialFlightSearchState,
  flightTypes,
} from './FlightSearchContext.types';
import { DateTime } from 'luxon';

export const flightTypeMap: {
  [key: string]: FlightType;
} = {
  'one-way': flightTypes.oneWay,
  'round-trip': flightTypes.roundTrip,
  'multi-leg': flightTypes.multiCity,
};

const endDate = endOfNextMonth(new Date());

export const roundTripRequest = {
  startDate: new Date(),
  endDate: endDate ? endDate : new Date(),
  arrivalAirport: 1,
  departureAirport: 1,
  roundTripDays: 3,
  passengerCount: 1,
};

export const singleTripRequest = {
  startDate: new Date(),
  endDate: endDate ? endDate : new Date(),
  arrivalAirport: 1,
  departureAirport: 1,
  roundTripDays: undefined,
  passengerCount: 1,
};

export interface ActionType {
  type: string;
  payload: any;
}

export type ActionHandlerType = {
  [key: string]: () => typeof initialState;
};

export const initialState: TInitialFlightSearchState = {
  flightType: flightTypes.roundTrip,
  flightErrors: [],
  legs: [
    standardFlightObj,
    {
      ...standardFlightObj,
      date: null,
    },
  ],
  pricingRequest: roundTripRequest,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setPricingRequest: () => {},
  isLegendOpen: false,
  displayDate: undefined,
  footerPricing: '',
  pricingData: undefined,
};

export function FlightSearchProvider({
  children,
}: IFlightSearchProviderProps): JSX.Element {
  const reducer = (
    state: typeof initialState,
    action: ActionType,
  ) => {
    const { payload, type } = action;

    const actionHandlers: ActionHandlerType = {
      updateLegErrors: () => {
        const updatedLegs = state.legs;
        updatedLegs[payload.legNumber].errors =
          payload.legErrors;
        return {
          ...state,
          legs: updatedLegs,
        };
      },
      updateFlightErrors: () => ({
        ...state,
        flightErrors: payload.flightErrors,
      }),
      updateFromURL: () => ({
        ...state,
        legs: payload.legs,
        flightType: payload.flightType,
        displayDate: payload.displayDate,
        pricingRequest: payload.pricingRequest,
      }),
      updateAirports: () => ({
        ...state,
        legs: payload.legs,
        flightType: payload.flightType,
        pricingRequest: payload.pricingRequest,
      }),
      resetState: () => ({
        ...initialState,
      }),
      updateLeg: () => {
        const updatedLegs = state.legs;
        updatedLegs[payload.legNumber] = payload.leg;
        return {
          ...state,
          legs: updatedLegs,
        };
      },
      replaceLegsRoundTrip: () => {
        const previousPricingRequest = state.pricingRequest;
        const firstFlight = payload.legs[0];
        if (
          firstFlight.departureAirport &&
          firstFlight.arrivalAirport
        ) {
          const newPricingRequest: PricingParameters = {
            ...previousPricingRequest,
            departureAirport: parseInt(
              firstFlight.departureAirport.id,
            ),
            arrivalAirport: parseInt(
              firstFlight.arrivalAirport.id,
            ),
          };
          return {
            ...state,
            legs: payload.legs,
            pricingRequest: newPricingRequest,
          };
        }

        return {
          ...state,
          legs: payload.legs,
        };
      },
      replaceLegsForDeals: () => {
        // THIS ISNT GREAT BUT WE NEED IT FOR DEALS
        // WOULD LOVE SOME IDEAS ON HOW TO AVOID THIS FROM FORMAT THING
        // .......if i try to do new Date(l.departTime) it will be off by 4 hours

        return {
          ...state,
          flightType: flightTypeMap[payload[0].tripType],
          legs: payload.map((l: any) => {
            const departTime = DateTime.fromFormat(
              l.departTime,
              "yyyy-MM-dd'T'HH:mm:ss'Z'",
            );
            const date = DateTime.fromFormat(
              l.date,
              "yyyy-MM-dd'T'HH:mm:ss'Z'",
            );
            return {
              ...l,
              departTime: departTime.toJSDate(),
              date: date.toJSDate(),
            };
          }),
        };
      },
      updateRoundTrip: () => ({
        ...state,
        legs: [payload.departure, payload.return],
      }),
      addLeg: () => ({
        ...state,
        legs: [
          ...state.legs.map((leg) => ({
            ...leg,
            dateOpen: false,
            timeOpen: false,
            passengersOpen: false,
          })),
          payload.leg,
        ],
      }),
      removeLeg: () => ({
        ...state,
        legs: state.legs.filter(
          (leg, index) => index !== payload.index,
        ),
      }),
      updateDisplayDate: () => ({
        ...state,
        displayDate: payload.displayDate,
      }),
      updateFooterPricing: () => ({
        ...state,
        displayDate: payload.footerPricing,
      }),
      updatePricingRequest: () => {
        return {
          ...state,
          pricingRequest: payload.request,
        };
      },
      updateIsLegendOpen: () => ({
        ...state,
        isLegendOpen: payload.isLegendOpen,
      }),
      updatePricingData: () => ({
        ...state,
        pricingData: payload.pricingData,
      }),

      switchFlightType: () => {
        const {
          departureAirport,
          arrivalAirport,
        } = state.legs[0];

        const baseLegs = [
          {
            ...standardFlightObj,
            departureAirport,
            arrivalAirport,
          },
          {
            ...standardFlightObj,
            departureAirport: arrivalAirport,
            arrivalAirport: departureAirport,
          },
        ];

        const baseState = {
          ...state,
          displayDate: '',
          legs: baseLegs,
          flightErrors: [],
          pricingRequest: roundTripRequest, // Default to round-trip request.
        };

        // Use the first leg only for one-way and multi-leg flights.
        const oneLegState = [baseLegs[0]];

        const baseAirports = {
          departureAirport: departureAirport
            ? parseInt(departureAirport.id, 10)
            : 1,
          arrivalAirport: arrivalAirport
            ? parseInt(arrivalAirport.id, 10)
            : 1,
        };

        switch (payload) {
          case flightTypes.roundTrip:
            // No changes needed from baseState for round-trip.
            return {
              ...baseState,
              flightType: payload,
              pricingRequest: {
                ...roundTripRequest,
                ...baseAirports,
              },
            };

          case flightTypes.multiCity:
          case flightTypes.oneWay:
            return {
              ...baseState,
              legs: oneLegState,
              flightType: payload,
              pricingRequest: {
                ...singleTripRequest,
                ...baseAirports,
              },
            };

          default:
            return state;
        }
      },
    };

    const handler =
      actionHandlers[type] || actionHandlers.default;
    return handler();
  };

  const [state, dispatch] = useReducer(
    reducer,
    initialState,
  );

  return (
    <FlightSearchContext.Provider
      value={{ state, dispatch }}
    >
      {children}
    </FlightSearchContext.Provider>
  );
}

export const FlightSearchContext = React.createContext<FlightSearchContextType>(
  {
    state: initialState,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    dispatch: () => {},
  },
);
