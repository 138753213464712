import { Typography } from '@wheelsup/wu-react-components';
import { LabelContainer } from '../TextGroupIconTextVertical/TextGroupIconTextVertical.styles';
import { IBar } from './BarChart.interfaces';
import {
  Bar,
  BarsSection,
  BarContainer,
  BottomLabelContainer,
  TopLabelContainer,
  BarChartContainer,
  BottomLabelWrapper,
  BottomLabelInTwoLines,
} from './BarChart.styles';
import { useRef } from 'react';

const BarChart = ({
  bars,
  onBarSelect,
  maxHeightOfBarAndTopLabel,
  bottomLabelIn2LinesForLimitedSpace = false,
}: {
  bars: IBar[];
  onBarSelect?: (i: number) => void;
  maxHeightOfBarAndTopLabel: number;
  bottomLabelIn2LinesForLimitedSpace?: boolean;
}) => {
  const maxValue = Math.max(
    ...bars.map((e) => e.value ?? 0),
  );
  const containerRef = useRef<HTMLDivElement>(null);

  return (
    <BarChartContainer ref={containerRef}>
      <BarsSection
        maxHeightOfBarAndTopLabel={
          maxHeightOfBarAndTopLabel
        }
      >
        {bars.map(({ topLabel, value, isSelected }, i) => (
          <BarContainer
            key={i}
            ratioOfMaxHeight={value / maxValue || 1}
          >
            <TopLabelContainer>
              <Typography
                variant={
                  isSelected ? 'body01Bold' : 'body01'
                }
                align="center"
              >
                {topLabel}
              </Typography>
            </TopLabelContainer>
            <Bar
              isSelected={isSelected}
              onClick={() => onBarSelect && onBarSelect(i)}
            />
          </BarContainer>
        ))}
      </BarsSection>
      <BottomLabelWrapper
        addMarginForScroll={
          containerRef.current &&
          containerRef.current?.scrollWidth >
            containerRef.current?.clientWidth
        }
      >
        {bars.map(({ bottomLabel }, i) => (
          <BottomLabelContainer key={i}>
            <Typography variant="caption" align="center">
              {bottomLabelIn2LinesForLimitedSpace ? (
                <BottomLabelInTwoLines>
                  <div>{bottomLabel?.labelLine1}</div>
                  <div>{bottomLabel?.labelLine2}</div>
                </BottomLabelInTwoLines>
              ) : (
                bottomLabel?.label
              )}
            </Typography>
          </BottomLabelContainer>
        ))}
      </BottomLabelWrapper>
    </BarChartContainer>
  );
};

export default BarChart;
