import { useEffect, useState, useRef } from 'react';
import { IAirportSearchPopover } from './AirportSearchPopover.interfaces';
import { AirportSearchListItem } from '@molecules';
import {
  MenuItemContainer,
  MenuItemContainerEmptyState,
  HeaderContainer,
  InstructionsContainer,
  Subpage,
} from '../AirportSearchPopover/AirportSearchPopover.styles';
import { useOnClickOutside } from '@hooks';
import Loader from '../Loader/Loader';
import CustomSubpage from '../CustomSubpage/CustomSubpage';
import { Typography } from '@wheelsup/wu-react-components';

export default function AirportSearchPopover({
  waitingForResponse = false,
  searchAirports,
  isSearching,
  emptySearchResultsMessage,
  savedAirports,
  savedAirportsHeader,
  savedAirportsInstructions,
  recentAirports,
  recentlySearchedAirportsHeader,
  anchorEl,
  onChange,
  handleClose,
  open,
  highDensityCopy,
  conditionalCopy,
  pinImage,
  deleteImage,
  homeImage,
  inputValue,
  saveAction,
  deleteAction,
}: IAirportSearchPopover) {
  const [searchIsEmpty, setSearchIsEmpty] = useState<
    boolean | undefined
  >(false);
  const [showEmptyMessage, setShowEmptyMessage] = useState<
    boolean | undefined
  >(false);

  const [
    searchAirportsState,
    setSearchAirportsState,
  ] = useState(searchAirports);
  const popperRef = useRef(null);

  const setAirports = () => {
    if (isSearching) {
      setSearchAirportsState(searchAirports);
    } else {
      setSearchAirportsState([]);
    }
  };

  const handleEmptySearchResults = () => {
    const emptySearch =
      isSearching &&
      inputValue.length >= 3 &&
      searchAirports &&
      searchAirports.length < 1;
    setSearchIsEmpty(emptySearch);
    setShowEmptyMessage(emptySearch);
  };

  useEffect(setAirports, [
    searchAirports,
    savedAirports,
    recentAirports,
    isSearching,
  ]);
  useEffect(handleEmptySearchResults, [
    isSearching,
    inputValue,
    searchAirports,
  ]);

  useOnClickOutside(popperRef, handleClose);

  const inputLessThanThreshold = inputValue.length <= 3;

  if (waitingForResponse) {
    return (
      <Subpage
        ref={popperRef}
        onClick={(e) => e.stopPropagation()}
      >
        <CustomSubpage width="525px" maxHeight={'500px'}>
          <Loader />
        </CustomSubpage>
      </Subpage>
    );
  } else if (searchIsEmpty && showEmptyMessage) {
    return (
      <Subpage
        ref={popperRef}
        onClick={(e) => e.stopPropagation()}
      >
        <CustomSubpage width="475px" maxHeight={'500px'}>
          <MenuItemContainerEmptyState disabled>
            {emptySearchResultsMessage}
          </MenuItemContainerEmptyState>
        </CustomSubpage>
      </Subpage>
    );
  } else {
    return (
      <>
        {open && (
          <Subpage
            ref={popperRef}
            onClick={(e) => e.stopPropagation()}
          >
            <CustomSubpage
              width="475px"
              maxHeight={'500px'}
              height="auto"
              scrollVisible
            >
              <>
                {searchAirportsState?.map((airport) => (
                  <MenuItemContainer
                    key={airport.id}
                    onClick={(e: React.ChangeEvent<{}>) => {
                      onChange(e, airport);
                    }}
                  >
                    <AirportSearchListItem
                      {...airport}
                      highDensityCopy={highDensityCopy}
                      conditionalCopy={conditionalCopy}
                      pinImage={pinImage}
                      deleteImage={deleteImage}
                      homeImage={homeImage}
                      saveAction={saveAction}
                      deleteAction={deleteAction}
                      isSaved={false}
                    />
                  </MenuItemContainer>
                ))}

                {inputLessThanThreshold && (
                  <>
                    <HeaderContainer>
                      <Typography
                        variant="heading05Responsive"
                        color="primary"
                      >
                        Favorite airports
                      </Typography>
                    </HeaderContainer>

                    {savedAirports?.length == 0 && (
                      <InstructionsContainer disabled>
                        <Typography
                          variant="body02"
                          color="primary"
                        >
                          Select the heart icon to save an
                          airport
                          <br />
                          Save up to 3 airports.
                        </Typography>
                      </InstructionsContainer>
                    )}
                  </>
                )}

                {inputLessThanThreshold &&
                  savedAirports?.map((airport) => (
                    <MenuItemContainer
                      key={airport.id}
                      onClick={(
                        e: React.ChangeEvent<{}>,
                      ) => {
                        onChange(e, airport);
                      }}
                    >
                      <AirportSearchListItem
                        {...airport}
                        highDensityCopy={highDensityCopy}
                        conditionalCopy={conditionalCopy}
                        pinImage={pinImage}
                        deleteImage={deleteImage}
                        homeImage={homeImage}
                        saveAction={saveAction}
                        deleteAction={deleteAction}
                        isSaved={true}
                      />
                    </MenuItemContainer>
                  ))}

                {(inputLessThanThreshold || inputValue) && (
                  <>
                    <HeaderContainer>
                      <Typography
                        variant="heading05Responsive"
                        color="primary"
                      >
                        Recently searched
                      </Typography>
                    </HeaderContainer>
                    {recentAirports?.length == 0 && (
                      <InstructionsContainer disabled>
                        <Typography
                          variant="body02"
                          color="primary"
                        >
                          Your search history will appear
                          here.
                        </Typography>
                      </InstructionsContainer>
                    )}
                  </>
                )}

                {(inputLessThanThreshold || inputValue) &&
                  recentAirports?.map((airport) => (
                    <MenuItemContainer
                      key={airport.id}
                      onClick={(
                        e: React.ChangeEvent<{}>,
                      ) => {
                        onChange(e, airport);
                      }}
                    >
                      <AirportSearchListItem
                        {...airport}
                        highDensityCopy={highDensityCopy}
                        conditionalCopy={conditionalCopy}
                        pinImage={pinImage}
                        deleteImage={deleteImage}
                        homeImage={homeImage}
                        saveAction={saveAction}
                        deleteAction={deleteAction}
                        isSaved={false}
                      />
                    </MenuItemContainer>
                  ))}
              </>
            </CustomSubpage>
          </Subpage>
        )}
      </>
    );
  }
}
