import styled from 'styled-components';

export const ButtonContainerWrapper = styled.div<{
  widthRem?: number;
  marginTop?: number;
}>`
  width: ${({ widthRem }) =>
    widthRem ? `${widthRem}rem` : '100%'};
  position: absolute;
  display: flex;
  justify-content: space-between;
  margin-top: ${({ marginTop }) => marginTop ?? 5.5}rem;
`;

export const PaginationContainer = styled.div<{
  width?: number;
  top?: number;
}>`
  position: absolute;
  display: flex;
  justify-content: center;
  top: ${({ top }) => top}rem;
  left: 43%;
  @media screen and (max-width: 960px) {
    left: 40%;
  }
  height: 20px;
  ${({ top, width }) => `
    top: ${top}rem;
    width: ${width}px;
  `}
`;

export const PaginationMarker = styled.div<{
  active?: boolean;
}>`
  background-color: #fff;
  width: ${({ active }) => (active ? '40px;' : '12px;')}
  height: 6px;
  border-radius: 4px;
  z-index: 10;
  transition: width 1s ease-in-out;
  margin-right: 10px;
`;
