import { IAirportItem } from '@atoms/AutoCompleteFlight/AutoCompleteFlight.interfaces';
import { CalendarDateWithPrice } from '@components/molecules/DateSection/dateHelper';
import { IAlertData } from '@components/molecules/FormFlightLeg/SearchFlight.interfaces';

export interface IFlightSearchProviderProps {
  children: JSX.Element;
}

export type Action =
  | {
      type: 'updateLeg';
      payload: {
        leg: FlightSearchObjType;
        legNumber: number;
      };
    }
  | {
      type: 'updateFlightType';
      payload: {
        flightType: FlightType;
      };
    }
  | {
      type: 'updateRoundTrip';
      payload: {
        departure: FlightSearchObjType;
        return: FlightSearchObjType;
      };
    }
  | {
      type: 'addLeg';
      payload: {
        leg: FlightSearchObjType;
      };
    }
  | {
      type: 'removeLeg';
      payload: {
        index: number;
      };
    }
  | {
      type: 'replaceLegsForDeals';
      payload: {
        legs: FlightSearchObjType[];
      };
    }
  | {
      type: 'replaceLegsRoundTrip';
      payload: {
        legs: FlightSearchObjType[];
      };
    }
  | {
      type: 'clearCalendarState';
      payload: {};
    }
  | {
      type: 'updatePricingRequest';
      payload: { request: PricingParameters };
    }
  | {
      type: 'switchFlightType';
      payload: string;
    }
  | {
      type: 'updateDisplayDate';
      payload: {
        displayDate: string;
      };
    }
  | {
      type: 'updateIsLegendOpen';
      payload: {
        isLegendOpen: boolean;
      };
    }
  | {
      type: 'updatePricingData';
      payload: {
        pricingData: Map<string, string>;
      };
    }
  | {
      type: 'updateFromURL';
      payload: {
        legs: FlightSearchObjType[];
        flightType: FlightType;
        displayDate: string;
        pricingRequest: PricingParameters;
      };
    }
  | {
      type: 'updateAirports';
      payload: {
        legs: FlightSearchObjType[];
        flightType: FlightType;
        pricingRequest: PricingParameters;
      };
    }
  | {
      type: 'resetState';
    }
  | {
      type: 'updateLegErrors';
      payload: {
        legNumber: number;
        legErrors: IAlertData[];
      };
    }
  | {
      type: 'updateFlightErrors';
      payload: {
        flightErrors: IAlertData[];
      };
    };

export type FlightType =
  | 'One way'
  | 'Round trip'
  | 'Multi city';

export enum flightTypes {
  oneWay = 'One way',
  roundTrip = 'Round trip',
  multiCity = 'Multi city',
}

export enum FlightTripType {
  ONE_WAY = 'ONE_WAY',
  ROUND_TRIP = 'ROUND_TRIP',
  MULTI_CITY = 'MULTI_CITY',
}

export const flightTripTypeMap: Record<
  string,
  FlightTripType
> = {
  [flightTypes.oneWay]: FlightTripType.ONE_WAY,
  [flightTypes.roundTrip]: FlightTripType.ROUND_TRIP,
  [flightTypes.multiCity]: FlightTripType.MULTI_CITY,
};

export interface FlightSearchContextType {
  state: {
    flightErrors: IAlertData[];
    flightType: FlightType;
    legs: FlightSearchObjType[];
    displayDate: string | undefined;
    pricingRequest: PricingParameters;
    isLegendOpen: boolean;
    pricingData:
      | Map<string, CalendarDateWithPrice>
      | undefined;
  };
  dispatch: React.Dispatch<Action>;
}

export type FlightSearchObjType = {
  departureAirport: IAirportItem | null;
  arrivalAirport: IAirportItem | null;
  airportError?: string | undefined;
  date: Date | null;
  dateString?: string;
  dateOpen?: boolean;
  dateError?: any;
  dateErrorString?: string | null;
  departTime?: Date;
  errors?: IAlertData[];
  timeError?: string | null;
  timeErrorsString?: string[];
  timeOpen?: boolean;
  numberOfPets: number;
  numberOfPassengers: number;
  passengersOpen?: boolean;
  passengersError?: string | null;
};

export type PricingParameters = {
  startDate: Date;
  endDate: Date;
  departureAirport: number;
  arrivalAirport: number;
  roundTripDays?: number;
  anchoredDate?: string;
  passengerCount?: number;
};

export type FlightObjTypeUpdate = Partial<FlightSearchObjType>;

export interface TInitialFlightSearchState {
  flightErrors: IAlertData[];
  flightType: FlightType;
  legs: FlightSearchObjType[];
  displayDate: string | undefined;
  pricingRequest: PricingParameters;
  setPricingRequest: React.Dispatch<
    React.SetStateAction<PricingParameters | undefined>
  >;
  isLegendOpen: boolean;
  footerPricing: string;
  pricingData:
    | Map<string, CalendarDateWithPrice>
    | undefined;
}
