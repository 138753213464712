import {
  DayContentProps,
  DayContent,
} from 'react-day-picker';
import { format } from 'date-fns';
import { useContext } from 'react';

import { SkeletonContainer } from '../../SubPageDatePicker.style';
import { Skeleton } from '@components/library';
import { PriceStyle } from '../CalendarPricing.style';
import { formatCompactNumber } from '../../../FormFlightLeg/formHelpers/utils';
import {
  CalendarDateWithPrice,
  getPricingDataKeyForDate,
} from '../../dateHelper';
import { FlightSearchContext } from '@components/molecules/SubPageFlightSearchV2/FlightSearchContext/FlightSearchContext';
import {
  CalendarDateType,
  IDateDecoration,
} from '@components/types';
import DateDecoration from './DateDecorationIcon';
import styled from 'styled-components';
import { BaseTooltip } from '@wheelsup/wu-react-components';
import { useFlightSearch } from '@components/molecules/SubPageFlightSearchV2/FlightSearchContext/FlightSearchHooks';

import { flightTypes } from '@components/molecules/SubPageFlightSearchV2/FlightSearchContext/FlightSearchContext.types';
import { TSharedProps } from '@components/molecules/SubPageFlightSearchV2/LegContainers/CalendarSection';
import { DateTime } from 'luxon';
import {} from '@components/molecules/SubPageFlightSearchV2/FlightSearchContext/FlightSearchContext.types';

const StyledTooltip = styled(BaseTooltip)`
  z-index: 100;
`;

// replaces React Day Picker's date renderer with our own implementation so we can add the decorations and prices
const DateWithPrice = ({
  dayContentProps,
  sharedProps,
  legNumber,
}: {
  dayContentProps: DayContentProps;
  sharedProps: TSharedProps;
  legNumber: number;
}) => {
  const disabled =
    dayContentProps?.activeModifiers?.disabled;

  const { flightType, legs } = useFlightSearch(
    useContext(FlightSearchContext),
  );

  const {
    dateDecorationMap,
    isPricingDataLoading,
    decorationMapLoading,
    pricingData,
  } = sharedProps;

  const dateTime = format(
    dayContentProps.date,
    'yyyy-MM-dd',
  );

  const isMultiLeg = flightType === flightTypes.multiCity;

  const thisCalendarDateWithPrice = pricingData?.get(
    getPricingDataKeyForDate(dayContentProps.date),
  ) as CalendarDateWithPrice;

  let bottomDecoration: IDateDecoration | undefined;
  let topDecoration: IDateDecoration | undefined;

  if (!decorationMapLoading && dateDecorationMap) {
    const dateKey = DateTime.fromJSDate(
      dayContentProps.date,
    ).toFormat('yyyy-MM-dd');

    const entry = dateDecorationMap[dateKey];
    bottomDecoration = entry?.bottom;
    topDecoration = entry?.top;
  }

  const shouldShowTooltip = () => {
    if (!bottomDecoration) return false;
    return (
      bottomDecoration.dateType === 'airportClosed' ||
      bottomDecoration.dateType === 'alreadyBooked'
    );
  };

  const hidePriceDecorations = [
    'alreadyBooked',
    'customQuote',
    'withinCalloutTime',
    'airportClosed',
  ];
  const showPrice = !hidePriceDecorations.includes(
    bottomDecoration?.dateType as string,
  );

  // otherwise just render the date

  /*
    top decoration

    if we have a top decoration, render it

    day content - if there's a bottom deocration, render the tooltip

    bottom decoration
  */
  const shouldShowLoading =
    (isPricingDataLoading || decorationMapLoading) &&
    !isMultiLeg;

  const isLoading =
    isPricingDataLoading || decorationMapLoading;

  // Check if we have airports selected for this leg
  const leg = legs[legNumber];
  const fromAirport = leg?.departureAirport;
  const toAirport = leg?.arrivalAirport;

  // We need to show only quote requests if the search includes an international airport
  const hasInternationalAirport =
    toAirport &&
    fromAirport &&
    pricingData &&
    pricingData.size === 0 &&
    !isLoading;

  // Determine if we have pricing data
  const havePricingData =
    pricingData && pricingData.size > 0;

  const showNonMultiCityBottomDecoration =
    !isMultiLeg && !isLoading;
  const showMultiCityBottomDecoration =
    bottomDecoration?.dateType !== 'customQuote' &&
    isMultiLeg &&
    !isLoading;

  const shouldShowPricing =
    !shouldShowLoading &&
    thisCalendarDateWithPrice?.price &&
    showPrice &&
    !isMultiLeg;

  const shouldShowDefaultRequestQuote =
    !shouldShowLoading &&
    !bottomDecoration &&
    !thisCalendarDateWithPrice?.price &&
    !isLoading &&
    havePricingData;

  // if it's disabled, don't show anything but the date.
  if (disabled) {
    return (
      <time dateTime={dateTime}>
        <DayContent {...dayContentProps} />
        {shouldShowLoading && (
          <SkeletonContainer className="skeletonContainer">
            <Skeleton variant="text" height={8} />
          </SkeletonContainer>
        )}
      </time>
    );
  }

  return (
    <time dateTime={dateTime}>
      {topDecoration && (
        <DateDecoration
          decoration={topDecoration.dateType}
        />
      )}
      {shouldShowTooltip() ? (
        <StyledTooltip
          content={
            <span>{bottomDecoration?.dateCopy}</span>
          }
        >
          <div>
            <DayContent {...dayContentProps} />
          </div>
        </StyledTooltip>
      ) : (
        <DayContent {...dayContentProps} />
      )}
      {shouldShowLoading && (
        <SkeletonContainer className="skeletonContainer">
          <Skeleton variant="text" height={8} />
        </SkeletonContainer>
      )}
      {shouldShowPricing && (
        <PriceStyle
          isCheap={thisCalendarDateWithPrice?.isCheap}
          className="priceStyle"
        >
          {`${
            thisCalendarDateWithPrice.price.symbol
          }${formatCompactNumber(
            thisCalendarDateWithPrice.price.amount,
          )}`}
        </PriceStyle>
      )}
      {bottomDecoration &&
        showNonMultiCityBottomDecoration && (
          <DateDecoration
            decoration={bottomDecoration.dateType}
          />
        )}
      {bottomDecoration &&
        showMultiCityBottomDecoration && (
          <DateDecoration
            decoration={bottomDecoration.dateType}
          />
        )}
      {!bottomDecoration &&
        (shouldShowDefaultRequestQuote ||
          hasInternationalAirport) &&
        !isMultiLeg && (
          <DateDecoration decoration={'customQuote'} />
        )}
    </time>
  );
};

export { DateWithPrice };
