import { Typography } from '@wheelsup/wu-react-components';
import { formatTimeStringToAMPM } from './CardPostFlightSearchTimePicker.utils';
import { DropdownBottomNote } from './CardPostFlightSearchTimePicker.styles';

const TimeDropdownBottomContent = ({
  legsCount,
  timeBlocksCount,
  reservationType,
  estimatedArrivalTime,
}: {
  legsCount: number;
  timeBlocksCount: number;
  reservationType: string;
  estimatedArrivalTime: string;
}) => {
  return (
    <>
      {legsCount == 1 && (
        <DropdownBottomNote>
          <Typography variant="body02">
            Est. arrival time{' '}
            {
              formatTimeStringToAMPM(estimatedArrivalTime)
                .ampmTime
            }
          </Typography>
        </DropdownBottomNote>
      )}
      {legsCount == 2 &&
        timeBlocksCount == 1 &&
        reservationType == 'instant-book' && (
          <div style={{ marginTop: 8 }}>
            <Typography variant="caption">
              Round trip prices shown
            </Typography>
          </div>
        )}
    </>
  );
};

export default TimeDropdownBottomContent;
