import { DateTime } from 'luxon';
import {
  IFlightLegTimePickerState,
  ILegInfo,
  ITimeBlockState,
} from './CardPostFlightSearchTimePicker.interfaces';
import { getFromSessionStorage } from '@components/utils/storage';
import transformLuggage, {
  LuggageRequestType,
} from '@components/utils/transformLuggage';
import {
  ILuggageData,
  ISelectedPassengerLegs,
  ISelectedPetsLegs,
} from '@features/Fly/FlySearchTripDetailsSlice';
import { IPassenger } from '@components/types';
import { getFormattedCurrency } from '@components/utils/formatCurrency';
import { trackAnalytics } from '@services/mixpanelService/mixpanel.service';

export const formatTimeStringToAMPM = (
  timeString: string,
) => {
  const dtObject = DateTime.fromFormat(
    timeString,
    'HH:mm:ss',
  );
  let ampmTime;
  if (dtObject.minute == 0) {
    ampmTime = dtObject.toFormat('h a');
  } else {
    ampmTime = dtObject.toFormat('h:mm a');
  }
  return { ampmTime, dtObject };
};

const findNewTimeBlockIndex = (
  selectedTimeObj: { label: string; value: string },
  flightLegsState: IFlightLegTimePickerState[],
  selectedLegIndex: number,
) => {
  const selectedTimeDtObj = DateTime.fromFormat(
    selectedTimeObj.value,
    'HH:mm:ss',
  );
  const currTimeBlockIndex =
    flightLegsState[selectedLegIndex]
      .selectedTimeBlockIndex;
  const currTimeBlock =
    flightLegsState[selectedLegIndex].timeBlocks[
    currTimeBlockIndex
    ];
  let newTimeBlockIndex = currTimeBlockIndex;
  if (
    currTimeBlockIndex > -1 &&
    (selectedTimeDtObj < currTimeBlock.startTimeDtObj ||
      selectedTimeDtObj > currTimeBlock.endTimeDtObj)
  ) {
    flightLegsState[selectedLegIndex]?.timeBlocks?.forEach(
      (timeBlock: ITimeBlockState, i: number) => {
        if (
          selectedTimeDtObj >= timeBlock.startTimeDtObj &&
          selectedTimeDtObj <= timeBlock.endTimeDtObj
        ) {
          newTimeBlockIndex = i;
        }
      },
    );
  }
  return newTimeBlockIndex;
};

export const getSelectedPrice = (
  selectedTimeObj: { label: string; value: string },
  flightLegsState: IFlightLegTimePickerState[],
  selectedLegIndex: number,
) => {
  return getFormattedCurrency(
    {
      amount: flightLegsState[selectedLegIndex].timeBlocks[
        findNewTimeBlockIndex(
          selectedTimeObj,
          flightLegsState,
          selectedLegIndex,
        )
      ].price as number,
      unit: 'USD',
      symbol: '$',
    },
    0,
  );
};

export const generateTimeOptions = (
  tBlocks: ITimeBlockState[],
) => {
  const options: {
    label: string;
    value: string;
  }[] = [];
  tBlocks.forEach((e: ITimeBlockState) => {
    const startTime = DateTime.fromFormat(
      e.startTime,
      'HH:mm:ss',
    );
    const endTime = DateTime.fromFormat(
      e.endTime,
      'HH:mm:ss',
    );
    let option = startTime.set({
      minute: 30,
      second: 0,
      millisecond: 0,
    });
    if (option < startTime) {
      option = option.plus({ minutes: 30 });
    } else if (
      startTime.minute === 0 &&
      startTime.second === 0
    ) {
      option = startTime.startOf('second');
    }
    while (option <= endTime) {
      options.push({
        label: option.toFormat('h:mm a'),
        value: option.toFormat('HH:mm:ss'),
      });
      option = option.plus({ minutes: 30 });
    }
  });
  return options;
};

export const computeNewLegsData = (
  selectedTimeObj: { label: string; value: string },
  flightLegsState: IFlightLegTimePickerState[],
  selectedLegIndex: number,
) => {
  const newArr = [...flightLegsState];
  const updatedSelectedLeg = {
    ...newArr[selectedLegIndex],
    selectedTime: { ...selectedTimeObj },
  };
  const selectedTimeDtObj = DateTime.fromFormat(
    selectedTimeObj.value,
    'HH:mm:ss',
  );
  const selectedTimeBlockIndex =
    updatedSelectedLeg.selectedTimeBlockIndex;
  const currentTimeBlock =
    updatedSelectedLeg.timeBlocks[selectedTimeBlockIndex];
  if (
    selectedTimeBlockIndex > -1 &&
    (currentTimeBlock.startTimeDtObj > selectedTimeDtObj ||
      currentTimeBlock.endTimeDtObj < selectedTimeDtObj)
  ) {
    let newTimeBlockIndex = -1;
    const newTimeBlocks = updatedSelectedLeg.timeBlocks.map(
      (timeBlock: ITimeBlockState, i: number) => {
        if (
          selectedTimeDtObj >= timeBlock.startTimeDtObj &&
          selectedTimeDtObj <= timeBlock.endTimeDtObj
        ) {
          newTimeBlockIndex = i;
          return { ...timeBlock, isSelected: true };
        }
        return { ...timeBlock };
      },
    );
    newTimeBlocks[
      selectedTimeBlockIndex
    ].isSelected = false;
    updatedSelectedLeg.timeBlocks = newTimeBlocks;
    updatedSelectedLeg.selectedTimeBlockIndex = newTimeBlockIndex;
  }
  newArr[selectedLegIndex] = updatedSelectedLeg;
  return newArr;
};

export const timePickerDropdownChangeApiPayload = (
  selectedPassengersLeg: ISelectedPassengerLegs[],
  selectedPetsLegs: ISelectedPetsLegs[],
  luggages: ILuggageData,
  flightLegsState: IFlightLegTimePickerState[],
  departureTime: string,
  selectedLegIndex: number,
) => {
  const storedSearchCriteria = getFromSessionStorage(
    'searchCriteria',
  );
  const legsInfo: ILegInfo[] = flightLegsState.map(
    (searchResultLegInfo: any, index: number) => {
      let leadPassenger: IPassenger | undefined;
      const passengers: any[] = [];
      let luggage: LuggageRequestType;
      (
        (
          selectedPassengersLeg[index] ??
          selectedPassengersLeg[0]
        )?.selectedPassengers ?? []
      ).forEach((pass: IPassenger) => {
        if (pass.isLeadPassenger) {
          leadPassenger = pass;
        }
        passengers.push(pass);
      });
      (
        (selectedPetsLegs[index] ?? selectedPetsLegs[0])
          ?.selectedPets ?? []
      ).forEach((pet: any) => {
        passengers.push({
          id: pet?.id,
          firstName: pet?.name,
          isPet: true,
          isLead: false,
        });
      });

      if (Object.keys(luggages).length > 1) {
        luggage = transformLuggage(
          luggages[index]?.luggageData,
        );
      } else {
        luggage = transformLuggage(
          luggages[0]?.luggageData,
        );
      }

      return {
        passengers,
        luggage,
        requestLegId: flightLegsState[index]?.requestLegId,
        departureTime:
          index === selectedLegIndex
            ? departureTime
            : flightLegsState[index].selectedTime.value,
        todLastSelectedLeg: index === selectedLegIndex,
      };
    },
  );
  let tripType = 'one-way';
  if (storedSearchCriteria.flightType === 'Round trip')
    tripType = 'round-trip';
  if (storedSearchCriteria.flightType === 'Multi city')
    tripType = 'multi-leg';
  return {
    clientId: storedSearchCriteria.clientId,
    resultId: storedSearchCriteria.searchId,
    tripType,
    reservationType: storedSearchCriteria.reservationType,
    flightSearchSource:
      storedSearchCriteria.flightSearchSource,
    bookingRequestLegDetails: legsInfo,
  };
};

export const fireMixpanelEventOnTimeChange = (
  selectedTimeObj: { label: string; value: string },
  flightLegsState: IFlightLegTimePickerState[],
  selectedLegIndex: number,
) => {
  const newTimeBlockIndex = findNewTimeBlockIndex(
    selectedTimeObj,
    flightLegsState,
    selectedLegIndex,
  );
  const hasMultipleTimeBlocks =
    flightLegsState[selectedLegIndex].timeBlocks.length > 1;
  const selectedTimeBlock =
    flightLegsState[selectedLegIndex].timeBlocks[
    newTimeBlockIndex
    ];
  trackAnalytics({
    trackingTitle:
      selectedLegIndex === 0
        ? 'Select_FirstLegSpecificTime_Dropdown'
        : 'Select_SecondLegSpecificTime_Dropdown',
    properties: [
      {
        key: 'Time Selected',
        value: selectedTimeObj.label,
      },
      {
        key: 'Price Selected',
        value: getSelectedPrice(
          selectedTimeObj,
          flightLegsState,
          selectedLegIndex,
        ),
      },
      {
        key: 'Price Is Cheapest',
        value:
          selectedTimeBlock.price ===
          Math.min(
            ...flightLegsState[
              selectedLegIndex
            ].timeBlocks
              .map((e) => e.price as number)
              .filter((e) => e),
          ) && hasMultipleTimeBlocks,
      },
      {
        key: 'Price Is Most Expensive',
        value:
          selectedTimeBlock.price ===
          Math.max(
            ...flightLegsState[
              selectedLegIndex
            ].timeBlocks
              .map((e) => e.price as number)
              .filter((e) => e),
          ) && hasMultipleTimeBlocks,
      },
      {
        key: 'Has Multiple Time Blocks',
        value: hasMultipleTimeBlocks,
      },
    ],
  });
};
