import { getFormattedCurrency } from '@components/utils/formatCurrency';
import { Typography } from '@wheelsup/wu-react-components';
import { DropdownRightPriceSection } from './CardPostFlightSearchTimePicker.styles';

const TimeDropdownRightContent = ({
  legsCount,
  timeBlocksCount,
  reservationType,
  price,
  timeSlot,
}: {
  legsCount: number;
  timeBlocksCount: number;
  reservationType: string;
  price: number | undefined;
  timeSlot: string | undefined;
}) => {
  return (
    <>
      {timeBlocksCount == 1 &&
        reservationType == 'instant-book' && (
          <DropdownRightPriceSection>
            <Typography variant="body01Bold">
              {price
                ? getFormattedCurrency(
                    {
                      amount: price,
                      unit: 'USD',
                      symbol: '$',
                    },
                    0,
                  )
                : null}
            </Typography>
            <Typography variant="caption">
              {timeSlot}
            </Typography>
          </DropdownRightPriceSection>
        )}
      {reservationType == 'instant-book' &&
        legsCount == 2 &&
        timeBlocksCount > 1 && (
          <DropdownRightPriceSection>
            <Typography variant="caption">
              Round trip prices shown
            </Typography>
          </DropdownRightPriceSection>
        )}
    </>
  );
};

export default TimeDropdownRightContent;
