import styled from 'styled-components';
import { solidColors } from '@constants/styles/colors.constants';

export const PickerContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 12px;
`;

export const PickerWrapper = styled.div`
position: 'absolute',
marginTop: '5px',
left: '0',
right: '0',
minWidth: '390px',
`;

export const PassengerPickerContainer = styled.div`
  display: flex;
  position: relative;
  top: 2px;
`;

export const PassengerPickerIcon = styled.div<{
  disable?: boolean;
}>`
  position: absolute;
  right: 0;
  top: 18px;
  color: ${({ disable }) =>
    disable
      ? `${solidColors.mediumGray.color}`
      : `${solidColors.upBlue.color}`};
`;

export const HrStyled = styled.hr`
  border: 1px solid ${solidColors.mediumGray.color};
  width: 100%;
  margin: -15px 0px;
`;

export const PickerGroup = styled.div`
  display: contents;
`;

export const ChipContainer = styled.div`
  margin-bottom: 30px;
`;

export const SwitchContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const SwitchWrapper = styled.div`
  margin-right: 2px;
  margin-bottom: 2px;
`;
