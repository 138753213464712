import AmexCardInfo from '@components/molecules/AmexCardInfo/AmexCardInfo';
import BorderedTextGroup from '@molecules/BorderedTextGroup/BorderedTextGroup';
import ButtonView from '@atoms/ButtonView/ButtonView';
import CardAccordionBulletPoints from '@components/molecules/CardAccordionBulletPoints/CardAccordionBulletPoints';
import CardAccordionHighMidPriorityAlert from '@components/organisms/CardAccordionHighMidPriorityAlert/CardAccordionHighMidPriorityAlert';
import CardAircraftDetails from '@components/molecules/CardAircraftDetails/CardAircraftDetails';
import CardAircraftDetailsSubpage from '@components/organisms/CardAircraftDetailsSubpage/CardAircraftDetailsSubpage';
import CardDayOfFlightContact from '@components/molecules/CardDayOfFlightContact/CardDayOfFlightContact';
import CardFlight from '@components/molecules/CardFlight/CardFlight';
import CardFlightDeal from '@components/molecules/CardFlightDeal/CardFlightDeal';
import CardHeadlineSearchSelectCTADismissSelect from '@components/molecules/CardHeadlineSearchSelectCTADismissSelect/CardHeadlineSearchSelectCTADismissSelect';
import CardHeadlinesubcopyCTAImageOversizedWEBONLY from '@molecules/CardHeadlinesubcopyCTAImageOversizedWEBONLY/CardHeadlinesubcopyCTAImageOversizedWEBONLY';
import CardImageCarouselHeadlineSubcopyCTA from '@components/molecules/CardImageCarouselHeadlineSubcopyCTA/CardImageCarouselHeadlineSubcopyCTA';
import CardImageHeadlineIconsSubCopyListCTA from '@molecules/CardImageHeadlineIconsSubCopyListCTA/CardImageHeadlineIconsSubCopyListCTA';
import CardImageTitleHeadlineCTA from '@molecules/CardImageTitleHeadlineCTA/CardImageTitleHeadlineCTA';
import CardLowPriorityAlertNotifications from '@molecules/CardLowPriorityAlertNotifications/CardLowPriorityAlertNotifications';
import CardNumberPicker from '@components/molecules/CardNumberPicker/CardNumberPicker';
import CardPassengerDetails from '@molecules/CardPassengerDetails/CardPassengerDetails';
import CardPastFlight from '@components/molecules/CardPastFlight/CardPastFlight';
import CardPetDetails from '@molecules/CardPetDetails/CardPetDetails';
import CardPostBookingRequest from '@molecules/CardPostBookingRequest/CardPostBookingRequest';
import CardPriceBreakdown from '@molecules/CardPriceBreakdown/CardPriceBreakdown';
import CardPriceBreakdown_v2 from '@molecules/CardPriceBreakdown/CardPriceBreakdown_v2';
import CardPriceRange from '@molecules/CardPriceRange/CardPriceRange';
import CardPrivateFlightSearchResult_v2 from '@components/molecules/CardPrivateFlightSearchResult_v2/CardPrivateFlightSearchResult_v2';
import CardRequestedBookedFlightStatusCard from '@molecules/CardRequestedBookedFlightStatusCard/CardRequestedBookedFlightStatusCard';
import CardRequestedQuote from '@molecules/CardRequestedQuote/CardRequestedQuote';
import CardReservationDetails from '@molecules/CardReservationDetails/CardReservationDetails';
import CardSelectedPayments from '@molecules/CardSelectedPayments/CardSelectedPayments';
import CardSnackbar from '@components/molecules/CardSnackbar/CardSnackbar';
import CardTextGroupIconBody from '@molecules/CardTextGroupIconBody/CardTextGroupIconBody';
import CardThreeOptionPicker from '@components/molecules/CardThreeOptionPicker/CardThreeOptionPicker';
import CarouselCarouselArrowWEBONLY from '@components/organisms/CarouselCarouselArrowWEBONLY/CarouselCarouselArrowWEBONLY';
import CarouselList from '@components/organisms/CarouselList/CarouselList';
import CarouselListLine from '@components/molecules/CarouselListLine/CarouselListLine';
import ContainerCardElementsWEBONLY from '@components/molecules/ContainerCardElementsWEBONLY/ContainerCardElementsWEBONLY';
import ContainerColororImageBackgroundWEBONLY from '@molecules/ContainerColororImageBackgroundWEBONLY/ContainerColororImageBackgroundWEBONLY';
import ContainerEmpty from '@atoms/ContainerEmpty';
import ContainerEmptyColumns from '@atoms/ContainerEmptyColumns/ContainerEmptyColumns';
import ContainerHeadlineBGSubnav from '@components/organisms/ContainerHeadlineBGSubnav/ContainerHeadlineBGSubnav';
import ContainerHeadlineSubCopyCTA from '@molecules/ContainerHeadlineSubCopyCTA/ContainerHeadlineSubCopyCTA';
import ContainerHeadlineSubCopyImageCTAHorizontalWEBONLY from '@molecules/ContainerHeadlineSubCopyImageCTAHorizontalWEBONLY/ContainerHeadlineSubCopyImageCTAHorizontalWEBONLY';
import ContainerMultiLegTripFlightSummary from '@components/molecules/ContainerMultiLegTripFlightSummary/ContainerMultiLegTripFlightSummary';
import ContainerRoundTripFlightSummary from '@components/molecules/ContainerRoundTripFlightSummary/ContainerRoundTripFlightSummary';
import ContainerSubNav from '@components/organisms/ContainerSubNav/ContainerSubNav';
import ContainerTripLuggageEditor from '@components/molecules/ContainerTripLuggageEditor/ContainerTripLuggageEditor';
import ContainerTripPassengerEditor from '@molecules/ContainerTripPassengerEditor/ContainerTripPassengerEditor';
import ContainerVerticalSpacer from '@components/molecules/ContainerVerticalSpacer/ContainerVerticalSpacer';
import Error from '@molecules/Error/Error';
import FlightLegItemLuggageInfo from '@molecules/FlightLegItemLuggageInfo/FlightLegItemLuggageInfo';
import FlightLegItemPassengerInfo from '@molecules/FlightLegItemPassengerInfo/FlightLegItemPassengerInfo';
import FooterWebOnly from '@components/organisms/FooterWebOnly/FooterWebOnly';
import FormFieldAirportSearchDashboard from '@components/molecules/FormFieldAirportSearchDashboard';
import FormFieldFreeTypeLarge from '@components/molecules/FormFieldFreeTypeLarge/FormFieldFreeTypeLarge';
import FormFieldTitleDropDown from '@components/atoms/FormFieldTitleDropDown/FormFieldTitleDropDown';
import FormFieldTitleFreeType from '@components/molecules/FormFieldTitleFreeType/FormFieldTitleFreeType';
import HeaderView from '@components/atoms/HeaderView/HeaderView';
import ImagePlaneTailRight from '@molecules/ImagePlaneTailRight/ImagePlaneTailRight';
import ImageView from '@molecules/ImageView/ImageView';
import ListAccordionCTAHeadlineSubCopy from '@molecules/ListAccordionCTAHeadlineSubCopy/ListAccordionCTAHeadlineSubCopy';
import ListCTAIconHeadline from '@components/molecules/ListCTAIconHeadline/ListCTAIconHeadline';
import ListFBOView from '@molecules/ListFBOView/ListFBOView';
import ListItemProfile from '@molecules/ListItemProfile/ListItemProfile';
import ListItemTitleIconBody from '@components/molecules/ListItemTitleIconBody/ListItemTitleIconBody';
import ListItemsWEBONLY from '@components/organisms/ListItemsWEBONLY/ListItemsWEBONLY';
import NavigationSectionToggleTwo from '@components/organisms/NavigationSectionToggleTwo/NavigationSectionToggleTwo';
import PasswordField from '@components/molecules/PasswordField/PasswordField';
import SubPageFlightLegSelection from '@components/organisms/SubPageFlightLegSelection/SubPageFlightLegSelection';
import SubPagePassengerAddEdit from '@components/organisms/SubPagePassengerAddEdit/SubPagePassengerAddEdit';
import SubPagePassengerPicker from '@components/molecules/PassengerPicker/PassengerPicker';
import SubPagePassengerSelection from '@components/organisms/SubPagePassengerSelection/SubPagePassengerSelection';
import SubPagePetAddEdit from '@components/organisms/SubPagePetAddEdit/SubPagePetAddEdit';
import SubPageLuggageSelection from '@components/organisms/SubpageLuggageSelection/SubpageLuggageSelection';
import TextGroupBodyCopyLink from '@molecules/TextGroupBodyCopyLink/TextGroupBodyCopyLink';
import TextGroupBodyCopySubCopy from '@components/molecules/TextGroupBodyCopySubCopy/TextGroupBodyCopySubCopy';
import TextGroupContextualGreetingMessage from '@components/molecules/TextGroupContextualGreetingMessage/TextGroupContextualGreetingMessage';
import SubPageAccountContact from '@organisms/SubPageAccountContact/SubPageAccountContact';
import SubPagePayment from '@organisms/SubPagePayment/SubPagePayment';
import SubPageSelectPayment from '@organisms/SubPageSelectPayment/SubPageSelectPayment';
import SubPageCreditCardForm from '@organisms/SubPageCreditCardForm/SubPageCreditCardForm';
import SubPageCheckWireTransfer from '@organisms/SubPageCheckWireTransfer/SubPageCheckWireTransfer';
import TextGroupIconBody from '@components/molecules/TextGroupIconBody/TextGroupIconBody';
import TextGroupSingleTextGroup from '@components/molecules/TextGroupSingleTextGroup/TextGroupSingleTextGroup';
import EmbeddedVideoPlayer from '@atoms/EmbeddedVideoPlayer/EmbeddedVideoPlayer';
import CarouselWrapWebOnly from '@organisms/CarouselWrapWebOnly/CarouselWrapWebOnly';
import TextGroupDoubleIconCTAs from '@molecules/TextGroupDoubleIconCTAs/TextGroupDoubleIconCTAs';
import CardHeadlineSubcopyListCTAsFooterText from '@components/molecules/CardHeadlineSubcopyListCTAsFooterText/CardHeadlineSubcopyListCTAsFooterText';
import BannerSubcopyImage from '@components/molecules/BannerSubcopyImage/BannerSubcopyImage';
import ContainerFlex from '@components/molecules/ContainerFlex/ContainerFlex';
import CarouselSmallArrowWebOnly from '@components/organisms/CarouselSmallArrowWebOnly/CarouselSmallArrowWebOnly';
import SubPageTripDetailsWebOnly from '@components/organisms/SubPageTripDetailsWebOnly/SubPageTripDetailsWebOnly';
import SubPageFlightDealResults from '@components/organisms/SubPageFlightDealResults/SubPageFlightDealResults';
import CardFlightDetailsEditor from '@components/molecules/CardFlightDetailsEditor/CardFlightDetailsEditor';
import CardCheckboxBullets from '@components/molecules/CardCheckboxBullets/CardCheckboxBullets';
import TextGroupCheckboxBodyCopyLink from '@components/molecules/TextGroupCheckboxBodyCopyLink/TextGroupCheckboxBodyCopyLink';
import TextGroupCheckboxRichLink from '@components/molecules/TextGroupCheckboxRichLink/TextGroupCheckboxRichLink';
import SubPageManageCreditCards from '@components/organisms/SubPageManageCreditCards/SubPageManageCreditCards';
import CardFlightDealResult from '@components/molecules/CardFlightDealResult/CardFlightDealResult';
import CardFboInfo from '@components/molecules/CardFboInfo/CardFboInfo';
import SubPageFlightSearch from '@components/molecules/SubPageFlightSearch/SubPageFlightSearch';
import { CardPostFlightSearchTimePicker } from '@components/molecules/CardPostFlightSearchTimePicker/CardPostFlightSearchTimePicker';

export const payloadKeys = {
  structure: 'pageStructure',
  embeded: 'emebededView',
  items: 'items,',
};

// Add new components in alphabetical order
export const Components = {
  BannerSubcopyImage,
  BorderedTextGroup,
  Button: ButtonView,
  CardAccordionBulletPoints,
  CardAccordionHighMidPriorityAlert,
  CardAircraftDetails,
  CardAircraftDetails_v2: CardAircraftDetailsSubpage,
  CardCheckboxBullets,
  CardFboInfo,
  CardFlightDetailsEditor,
  CardFlightDeal,
  CardFlightDealResult,
  CardLowPriorityAlertNotifications,
  CardImageCarouselHeadlineSubcopyCTA,
  CardImageHeadlineIconsSubCopyListCTA,
  CardImageTitleHeadlineCTA,
  CardHeadlinesubcopyCTAImageOversizedWEBONLY,
  CardHeadlineSubcopyListCTAsFooterText,
  CardHeadlineSearchSelectCTADismissSingleSelect: CardHeadlineSearchSelectCTADismissSelect,
  CardHeadlineSearchSelectCTADismissMultiSelect: CardHeadlineSearchSelectCTADismissSelect,
  CardNumberPicker,
  CardPassengerDetails,
  CardPastFlight,
  CardPetDetails,
  CardPostBookingRequest,
  CardPostFlightSearchTimePicker,
  CardPriceBreakdown,
  CardPriceBreakdown_v2,
  CardPrivateFlightSearchResult_v2,
  CardReservationDetails,
  CardRequestedBookedFlightStatusCard,
  CardRequestedQuote,
  CardSelectedPayments,
  CardSnackbar,
  CardTextGroupIconBody,
  CardThreeOptionPicker,
  CarouselCarouselArrowWEBONLY: CarouselCarouselArrowWEBONLY,
  CarouselList,
  CarouselListLine,
  CarouselSmallArrowWEBONLY: CarouselSmallArrowWebOnly,
  CarouselWrapWebOnly,
  ContainerEmpty: ContainerEmpty,
  ContainerEmptyColumns,
  ContainerCardElementsWEBONLY,
  ContainerColororImageBackgroundWEBONLY,
  ContainerFlex,
  ContainerHeadlineSubCopyImageCTAHorizontalWEBONLY,
  ContainerHeadlineSubCopyCTA,
  ContainerSearchResultsPage: ContainerEmpty, // This could be a new component, but mapping to ContainerEmpty for now
  ContainerSubNav,
  ContainerTripLuggageEditor,
  ContainerTripPassengerEditor,
  ContainerVerticalSpacer,
  Error: Error,
  EmbeddedVideoPlayer: EmbeddedVideoPlayer,
  FlightLegItemLuggageInfo,
  FlightLegItemPassengerInfo,
  FooterWEBONLY: FooterWebOnly,
  FormFieldAirportSearchDashboard: FormFieldAirportSearchDashboard,
  FormFieldFreeTypeLarge,
  FormFieldPasswordCreationFreeType: PasswordField,
  FormFieldTitleFreeType,
  FormFieldTitleDropDown,
  Image: ImageView,
  ImagePlaneTailRight,
  ListCTAIconHeadline,
  ListFBOView,
  ListItemTitleIconBody,
  NavigationSectionToggleTwo,
  SubPageFlightSearch: SubPageFlightSearch,
  SubPagePassengerPicker,
  SubPagePassengerAddEdit,
  SubPagePassengerSelection,
  TextGroupbodycopysubcopy: TextGroupBodyCopySubCopy,
  TextGroupSingleTextGroup,
  TextGroupCheckboxBodyCopyLink,
  TextGroupCheckboxRichLink,
  TextGroupContextualGreetingMessage,
  TextGroupHeadlineSubNavSubCopyImageSpecsWEBONLY: ContainerHeadlineBGSubnav,
  TextGroupIconBody,
  ListItemsWEBONLY,
  CardFlightItinerarySummary: CardFlight,
  ContainerRoundTripFlightSummary,
  ContainerMultiLegTripFlightSummary,
  CardPriceRange,
  ListAccordionCTAHeadlineSubCopy: ListAccordionCTAHeadlineSubCopy,
  ListItemProfile,
  TextGroupbodycopylink: TextGroupBodyCopyLink,
  HeaderView: HeaderView,
  SubPagePetAddEdit: SubPagePetAddEdit,
  CardDayOfFlightContact: CardDayOfFlightContact,
  SubPageAccountContact,
  SubPageLuggageSelection,
  SubPagePayment,
  SubPageSelectPayment,
  SubPageCreditCardForm,
  SubPageCheckWireTransfer,
  SubPageFlightLegSelection,
  SubpageTripDetailsWebOnly: SubPageTripDetailsWebOnly,
  TextGroupDoubleIconCTAs,
  TripLuggageEditorWebOnly: ContainerTripLuggageEditor,
  AmexCardInfo,
  SubPageFlightDealResults,
  SubPageManageCreditCards,
};
