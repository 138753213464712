import styled from 'styled-components';
import { IStyledRadio } from './Radio.interfaces';

const placementMap = {
  start: 'row-reverse',
  end: 'row',
  top: 'column-reverse',
  bottom: 'column',
};

export const RadioControlLabel = styled.div<IStyledRadio>`
  display: flex;
  gap: 8px;
  justify-content: center;
  align-items: center;
  width: fit-content;

  flex-direction: ${({ labelPlacement }) =>
    placementMap[labelPlacement ?? 'start']};

  margin-left: ${({ marginLeft }) => marginLeft ?? '0'};
  margin-right: ${({ marginRight }) => marginRight ?? '0'};
  margin-bottom: ${({ marginBottom }) =>
    marginBottom ?? '0'};
`;
