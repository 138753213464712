import { useEffect, useRef, useState } from 'react';

interface UseExponentialBackoffOptions {
  initialDelay: number;
  maxRetries: number;
  enabled?: boolean;
  onRetry?: () => void;
  shouldContinue?: () => boolean;
}

/**
 * A hook that implements exponential backoff for polling or retrying operations
 * 
 * @param options Configuration options for the exponential backoff
 * @param options.initialDelay Initial delay in milliseconds
 * @param options.maxRetries Maximum number of retries
 * @param options.enabled Whether the backoff is enabled (default: true)
 * @param options.onRetry Callback function to execute on each retry
 * @param options.shouldContinue Function that determines if polling should continue
 * 
 * @returns Object containing current state and control functions
 */
export const useExponentialBackoff = ({
  initialDelay,
  maxRetries,
  enabled = true,
  onRetry,
  shouldContinue,
}: UseExponentialBackoffOptions) => {
  const [currentDelay, setCurrentDelay] = useState(initialDelay);
  const retryCountRef = useRef(0);
  const [isActive, setIsActive] = useState(enabled);
  
  // Reset the backoff state
  const reset = () => {
    retryCountRef.current = 0;
    setCurrentDelay(initialDelay);
    setIsActive(enabled);
  };
  
  // Stop the backoff
  const stop = () => {
    setIsActive(false);
  };
  
  // Start or resume the backoff
  const start = () => {
    setIsActive(true);
  };

  // Update active state when enabled prop changes
  useEffect(() => {
    setIsActive(enabled);
  }, [enabled]);

  useEffect(() => {
    // Don't do anything if not active or max retries reached
    if (!isActive) {
      return;
    }
    
    if (retryCountRef.current >= maxRetries) {
      stop();
      return;
    }
    
    // Check if we should continue polling
    if (shouldContinue && !shouldContinue()) {
      stop();
      return;
    }
    
    const timeoutId = setTimeout(() => {
      // Execute the retry callback
      onRetry?.();
      
      // Increment retry count
      retryCountRef.current += 1;
      
      // Double the delay for next retry (exponential backoff)
      setCurrentDelay(prev => {
        const newDelay = prev * 2;
        return newDelay;
      });
    }, currentDelay);
    
    return () => {
      clearTimeout(timeoutId);
    };
  }, [isActive, currentDelay, maxRetries, onRetry, shouldContinue]);
  
  return {
    currentDelay,
    retryCount: retryCountRef.current,
    isActive,
    reset,
    stop,
    start,
    hasReachedMaxRetries: retryCountRef.current >= maxRetries,
  };
};

export default useExponentialBackoff; 