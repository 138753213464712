import { InputWrapper } from '@components/molecules/FormFlightLeg/SearchFlight.styles';
import PassengerPicker from '@components/molecules/PassengerPicker/PassengerPicker';
import {
  Icon,
  TextInput,
} from '@wheelsup/wu-react-components';
import { useContext, useState } from 'react';
import { FlightSearchContext } from '../FlightSearchContext/FlightSearchContext';
import { useFlightSearch } from '../FlightSearchContext/FlightSearchHooks';

const PassengerSection = ({
  legNumber,
}: {
  legNumber: number;
}) => {
  const { legs, updateLeg, isRoundTrip } = useFlightSearch(
    useContext(FlightSearchContext),
  );
  const leg = legs[legNumber];

  const getLabel = () => {
    if (isRoundTrip) {
      return legNumber === 0
        ? 'Depart Passengers'
        : 'Return Passengers';
    } else {
      return 'Passengers';
    }
  };
  return (
    <InputWrapper data-name="passengerInput">
      <TextInput
        onClick={() =>
          updateLeg({ passengersOpen: true }, legNumber)
        }
        name={'PassengersInput'}
        label={getLabel()}
        labelSize={'md'}
        variant={'md'}
        value={leg.numberOfPassengers + leg.numberOfPets}
        isReadOnly={true}
        leftIcon={
          <Icon color="secondary" name="person" size="s" />
        }
        errorMessage={
          leg.passengersError && 'ERROR MESSAGE COMING SOON'
        }
        className="selectedBorder"
      />
      <PassengerPicker
        title="Passengers"
        subtitle="Select the number of passengers"
        legNumber={legNumber}
      />
    </InputWrapper>
  );
};
export default PassengerSection;
