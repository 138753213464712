import { IRadio } from './Radio.interfaces';
import { RadioControlLabel } from './Radio.styles';
import { RadioItem } from '@wheelsup/wu-react-components';

const CustomRadio = ({
  id,
  marginLeft,
  marginRight,
  marginBottom,
  checked,
  label,
  labelPlacement = 'end',
  disabled = false,
  onChange,
}: IRadio) => {
  return (
    <RadioControlLabel
      marginLeft={marginLeft}
      marginRight={marginRight}
      marginBottom={marginBottom}
      labelPlacement={labelPlacement}
    >
      <RadioItem
        id={id}
        isChecked={checked}
        onChange={onChange}
        disabled={disabled}
      />
      <label htmlFor={id}>{label}</label>
    </RadioControlLabel>
  );
};

export default CustomRadio;
