import { useEffect, useState } from 'react';
import { ICardPrivateFlightSearchResult_v2 } from './CardPrivateFlightSearchResult.interfaces';
import { useViewport } from '@hooks';
import {
  ISelectFlightPayload,
  selectFlight,
} from '@features/Fly/searchFlightSlice';
import { useDispatch } from 'react-redux';
import { ActionTypes } from '@atoms/Action/Action.interfaces';
import { useNavigate } from 'react-router-dom';
import { paths } from '@routing/routerPaths';
import { useAppSelector } from '@app/hooks';
import {
  buttonPopupOpen,
  pagePopup,
  snackbarOpen,
} from '@features/Members/popupSlice';
import MemberTabPopup from '@molecules/MemberTabPopup/MemberTabPopup';
import {
  getFromSessionStorage,
  saveDataInLocalStorage,
  saveDataInSessionStorage,
} from '@components/utils/storage';
import CardRestyledDesktop from './CardRestyledDesktop';
import CardRestyledMobile from './CardRestyledMobile';
import { BREAKPOINTS } from '@components/utils/breakpoints';

export default function CardPrivateFlightSearchResult_v2(
  props: ICardPrivateFlightSearchResult_v2,
) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isButtonPopupOpen } = useAppSelector(
    (state) => state.popupOpen,
  );
  const [mobileCard, setMobileCard] = useState(false);
  const searchFlightData = getFromSessionStorage(
    'searchCriteria',
  );
  const { width } = useViewport();

  useEffect(() => {
    width <= BREAKPOINTS.MOBILE
      ? setMobileCard(true)
      : setMobileCard(false);
  }, [width]);

  const handlePopup = () => {
    dispatch(buttonPopupOpen(true));
    dispatch(pagePopup(true));
    dispatch(snackbarOpen(false));
  };

  const selectFlightHandler = () => {
    const actionType = props.button.action.type;
    const searchId = props.button.action.data;
    const searchPayload: ISelectFlightPayload = {
      searchId: searchId ? searchId : '',
      reservationType: props.reservationType,
    };
    saveDataInLocalStorage(
      'aircraftType',
      props.aircraftTypeLine2 ?? '',
    );
    if (
      actionType === ('page_next' as ActionTypes) &&
      searchId
    ) {
      dispatch(selectFlight(searchPayload));
      const searchCriteria = {
        ...searchFlightData,
        ...searchPayload,
      };
      saveDataInSessionStorage(
        'searchCriteria',
        searchCriteria,
      );
      navigate(paths.FLY_SEARCH_TRIP_DETAILS);
    } else if (
      actionType ===
      ('display_membership_lock' as ActionTypes)
    ) {
      handlePopup();
    }
  };

  const FlightCard = mobileCard
    ? CardRestyledMobile
    : CardRestyledDesktop;

  return (
    <>
      {isButtonPopupOpen && <MemberTabPopup />}
      <FlightCard
        {...props}
        selectFlight={selectFlightHandler}
        width={width}
      />
    </>
  );
}
