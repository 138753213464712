import Error from '@components/molecules/Error/Error';
import Footer from '../Footer/Footer';
import { Header } from '@organisms';
import { IMainLayout } from './Layout.interfaces';
import { Loader } from '@components/molecules';
import { getFromLocalStorage } from '@components/utils/storage';
import { useAppSelector } from '@app/hooks';

const MainLayout = ({
  children,
  showHeader,
  showFooter,
}: IMainLayout) => {
  const shouldShowLoadingPage = getFromLocalStorage(
    'shouldShowLoadingPage',
  );
  const { refreshIsLoading } = useAppSelector(
    (state) => state.handle401,
  );
  const { isAppLoading } = useAppSelector(
    (state) => state.appLoading,
  );

  const { errorPayload, errorOpen } = useAppSelector(
    (state) => state.error,
  );

  // this is to not show the whole layout when preparing an iframe
  if (shouldShowLoadingPage) {
    return (
      <>
        {refreshIsLoading && <Loader />}
        {errorOpen && (
          <Error open={true} data={errorPayload} />
        )}
        <div>{children}</div>
      </>
    );
  }

  const showLoader = refreshIsLoading || isAppLoading;
  return (
    <>
      {showLoader && <Loader />}
      {errorOpen && (
        <Error open={true} data={errorPayload} />
      )}
      {showHeader ? <Header /> : null}
      <div
        id={'middle-children'}
        style={{ minHeight: '100vh' }}
      >
        {children}
      </div>
      {showFooter ? <Footer /> : null}
    </>
  );
};

export default MainLayout;
