import { ReactElement, useContext } from 'react';
import { CardFlightLegContainer } from '../../CardFlightLeg/CardFlightLeg.styles';
import { FlightSearchContext } from '../FlightSearchContext/FlightSearchContext';

import FlightSection from './FlightSection';
import PassengerSectionV3 from './PassengerSectionV3';
import {
  InputWrapper,
  RowWrapper,
} from '@components/molecules/FormFlightLeg/SearchFlight.styles';

import { Divider } from '@wheelsup/wu-react-components';
import CalendarSection from './CalendarSection';
import { useViewport } from '@hooks';
import { useFlightSearch } from '../FlightSearchContext/FlightSearchHooks';
import {
  Button,
  Icon,
  Typography,
} from '@wheelsup/wu-react-components';
import { FlightObjType } from '../initialState';
import styled from 'styled-components';
import { flightTypes } from '../FlightSearchContext/FlightSearchContext.types';
import { CardAccordionHighMidPriorityAlert } from '@components/organisms';
import { IAlertData } from '@components/molecules/FormFlightLeg/SearchFlight.interfaces';
import TimeSection from '@components/molecules/TimeSection/TimeSection';
interface ILegContainer {
  legNumber: number;
  buttonElement?: ReactElement;
}

const RemoveButtonContainer = styled.div`
  display: flex;
  align-items: center;
`;

const AddFlightSectionMultiCity = styled.div`
  display: flex;
  justify-content: space-between;
`;

const LegContainerV3 = ({
  legNumber,
  buttonElement,
}: ILegContainer) => {
  const {
    isRoundTrip,
    flightType,
    legs,
    flightErrors,
    contextDispatch,
  } = useFlightSearch(useContext(FlightSearchContext));

  const { width } = useViewport();
  const isDesktop = width > 1000;

  const addLeg = () => {
    const lastLeg = legs[legs.length - 1];
    const newLeg: FlightObjType = {
      ...lastLeg,
      errors: [],
      dateOpen: false,
      timeOpen: false,
      passengersOpen: false,
      departureAirport: lastLeg.arrivalAirport,
      arrivalAirport: null,
    };
    contextDispatch({
      type: 'addLeg',
      payload: { leg: newLeg },
    });
  };

  const removeLeg = () => {
    contextDispatch({
      type: 'removeLeg',
      payload: { index: legNumber },
    });
  };
  const isMultiLeg = flightType === flightTypes.multiCity;

  const validationErrors: IAlertData[] = [];
  if (legNumber === 0) {
    // Get the overall flight errors only once to display at the top
    validationErrors.push(...flightErrors);
  }
  if (isRoundTrip) {
    // For round trip, get all of the errors from all of the legs
    for (const leg of legs) {
      if (leg.errors) {
        leg.errors.map((error) => {
          validationErrors.push(error);
        });
      }
    }
  } else {
    // For one-way and multi-ctiy, get per leg errors
    legs[legNumber]?.errors?.forEach((error) =>
      validationErrors.push(error),
    );
  }

  return (
    <CardFlightLegContainer
      data-name="LegContainer"
      id={`LegContainer${legNumber}`}
      legIndex={legNumber}
    >
      {validationErrors &&
        validationErrors.map((error, index) => (
          <CardAccordionHighMidPriorityAlert
            key={`AirportLeg${legNumber}Error${index}`}
            {...error.alert.data}
            alertPriority="high"
            id={`AirportLeg${legNumber}Error${index}`}
          />
        ))}
      {isMultiLeg && legNumber !== 0 && (
        <RemoveButtonContainer
          id={`RemoveButtonContainer${legNumber}`}
        >
          <Typography variant="heading04">
            Flight {legNumber + 1}
          </Typography>
          <Button
            variant="ghost"
            onPress={() => removeLeg()}
          >
            Remove
          </Button>
        </RemoveButtonContainer>
      )}
      <FlightSection legNumber={legNumber} />
      <>
        <RowWrapper withGap alignItemsTop>
          <InputWrapper
            data-name="InputWrapper"
            $fullWidth={isDesktop}
            id={`PassengerInputWrapper${legNumber}`}
          >
            <PassengerSectionV3 legNumber={legNumber} />
          </InputWrapper>
          <InputWrapper
            data-name="InputWrapper"
            $fullWidth={isDesktop}
            id={`CalendarInput${legNumber}`}
          >
            <CalendarSection legNumber={legNumber} />
          </InputWrapper>
          {isMultiLeg && (
            <InputWrapper
              data-name="InputWrapper"
              $fullWidth={isDesktop}
              id={`TimeInput${legNumber}`}
            >
              <TimeSection legNumber={legNumber} />
            </InputWrapper>
          )}
          {buttonElement && !isMultiLeg && (
            <InputWrapper data-name="InputWrapper">
              {buttonElement}
            </InputWrapper>
          )}
        </RowWrapper>
      </>
      {isMultiLeg &&
        legNumber === legs.length - 1 &&
        legs.length < 6 && (
          <>
            <Divider color="disabled" spacing={0} />
            <AddFlightSectionMultiCity>
              <Button
                leftIcon={
                  <Icon
                    name="add"
                    color="primary"
                    size="xs"
                  />
                }
                variant={'ghost'}
                onPress={addLeg}
              >
                ADD FLIGHT
              </Button>
              {buttonElement}
            </AddFlightSectionMultiCity>
          </>
        )}
    </CardFlightLegContainer>
  );
};

export default LegContainerV3;
