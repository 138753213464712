import styled from 'styled-components';

export const CardFlightLegContainer = styled.div<{
  legIndex: number;
}>`
  display: flex;
  flex-direction: column;
  min-height: auto;
  min-width: 100%;
  width: auto;
  gap: 24px;
  height: auto;
  position: relative;
  margin-top: ${({ legIndex }) => legIndex !== 0 && '24px'};
`;
