import { Typography } from '@wheelsup/wu-react-components';
import { Icon } from '@wheelsup/wu-react-components';
import {
  TimePickerAlertIconWrapper,
  TimePickerAlertWrapper,
} from './CardPostFlightSearchTimePicker.styles';

const TimePickerAlert = ({
  title,
  copy,
}: {
  title: string;
  copy: string;
}) => {
  return (
    <TimePickerAlertWrapper>
      <TimePickerAlertIconWrapper>
        <Icon
          name="alert-triangle"
          color="warning"
          size="s"
        />{' '}
      </TimePickerAlertIconWrapper>
      <div>
        <Typography
          variant="heading04Small"
          color="warning"
        >
          {title}
        </Typography>
        <Typography variant="body02" color="warning">
          {copy}
        </Typography>
      </div>
    </TimePickerAlertWrapper>
  );
};

export default TimePickerAlert;
